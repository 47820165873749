// App.tsx
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { enumerate } from './SpreadsheetUtility';
import constants from 'app/config/constants';
import {
  MdHelp,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import HelpModal from './HelpModal';

const SpreadsheetStatusbar = ({
  activePage,
  count,
  customStatusbarButtons,
  goToPage,
}) => {
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const pageCount = Math.ceil(count / constants.DPT_LOAD_LIMIT());

  return (
    <>
      <HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />

      <Navbar expand="lg" fixed="bottom" bg="light">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            <Button
              className="me-lg-1 mb-1 mb-lg-0"
              variant="dark"
              onClick={() => setShowHelpModal(true)}
            >
              <MdHelp /> <div className="d-inline-block d-lg-none">Q/A</div>
            </Button>
            {customStatusbarButtons}
          </Nav>
          <Nav className="ms-auto">
            {pageCount > 1 && (
              <InputGroup>
                <Button
                  variant="dark"
                  disabled={activePage === 0}
                  onClick={() => goToPage(1)}
                >
                  <MdKeyboardDoubleArrowLeft />
                </Button>
                <Button
                  variant="dark"
                  disabled={activePage === 0}
                  onClick={() => goToPage(activePage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </Button>
                <Form.Select
                  value={activePage}
                  onChange={e => goToPage(e.target.value)}
                >
                  {enumerate(pageCount).map(pageIndex => (
                    <option value={pageIndex}>
                      Page {pageIndex + 1} of {pageCount}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  variant="dark"
                  disabled={activePage === pageCount - 1}
                  onClick={() => goToPage(activePage + 1)}
                >
                  <MdKeyboardArrowRight />
                </Button>
                <Button
                  variant="dark"
                  disabled={activePage === pageCount - 1}
                  onClick={() => goToPage(pageCount - 1)}
                >
                  <MdKeyboardDoubleArrowRight />
                </Button>
              </InputGroup>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default SpreadsheetStatusbar;
