import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TbCircleFilled } from 'react-icons/tb';

import farm_flag_icon from '../../assets/FarmFlag.png';
import local_elections_icon from '../../assets/localelection.png';
import pro_2a_icon from '../../assets/2ndamendment.png';
import prochoice_icon from '../../assets/prochoice.png';
import prolife_icon from '../../assets/prolife.png';
import sportsmen_icon from '../../assets/sportsmen.png';
import veterans_icon from '../../assets/veterans.png';
import v_donates_to_animal_welfare_icon from '../../assets/animalflag.png';
import v_donates_to_arts_and_culture_icon from '../../assets/artsandcultureflag.png';
import v_donates_to_childrens_causes_icon from '../../assets/childrens causes.png';
import v_donates_to_healthcare_icon from '../../assets/healthcareflag.png';
import v_donates_to_international_aid_causes_icon from '../../assets/internationalaidflag.png';
import v_donates_to_liberal_causes_icon from '../../assets/liberal causes.png';
import v_donates_to_local_community_icon from '../../assets/local community flag.png';
import v_donates_to_wildlife_preservation_icon_ from '../../assets/wildlifepresservationflag.png';

const convertFlags = value =>
  value
    .replace(/V_/gi, '')
    .replace(/_/g, ' ')
    .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/(\b[a-z])/g, v => v.toUpperCase());

const displayInterests = interests => {
  if (!interests) return null;

  const interestIndex = {
    FarmFlag: {
      image: farm_flag_icon,
      tooltip: 'This donor supports farming.',
    },
    LocalElectionFlag: {
      image: local_elections_icon,
      tooltip: 'This donor frequently votes in local elections.',
    },
    ProChoiceFlag: {
      image: prochoice_icon,
      tooltip: 'This donor is prochoice.',
    },
    ProLifeFlag: {
      image: prolife_icon,
      tooltip: 'This donor is prolife.',
    },
    SecondAmendmentSupporterFlag: {
      image: pro_2a_icon,
      tooltip: 'This donor is a 2nd Amendment supporter.',
    },
    SportsmenFlag: {
      image: sportsmen_icon,
      tooltip: 'This donor is an avid sportsman.',
    },
    VeteransFlag: {
      image: veterans_icon,
      tooltip: 'This donor supports veterans.',
    },
    V_DONATES_TO_ANIMAL_WELFARE: {
      image: v_donates_to_animal_welfare_icon,
      tooltip: 'This donor supports animal welfare.',
    },
    V_DONATES_TO_ARTS_AND_CULTURE: {
      image: v_donates_to_arts_and_culture_icon,
      tooltip: 'This donor supports arts and culture.',
    },
    V_DONATES_TO_CHILDRENS_CAUSES: {
      image: v_donates_to_childrens_causes_icon,
      tooltip: "This donor supports children's causes.",
    },
    V_DONATES_TO_HEALTHCARE: {
      image: v_donates_to_healthcare_icon,
      tooltip: 'This donor supports healthcare.',
    },
    V_DONATES_TO_INTERNATIONAL_AID_CAUSES: {
      image: v_donates_to_international_aid_causes_icon,
      tooltip: 'This donor supports international aid causes.',
    },
    V_DONATES_TO_LIBERAL_CAUSES: {
      image: v_donates_to_liberal_causes_icon,
      tooltip: 'This donor supports liberal causes.',
    },
    V_DONATES_TO_LOCAL_COMMUNITY: {
      image: v_donates_to_local_community_icon,
      tooltip: 'This donor supports the local community.',
    },
    V_DONATES_TO_WILDLIFE_PRESERVATION: {
      image: v_donates_to_wildlife_preservation_icon_,
      tooltip: 'This donor supports wildlife preservation.',
    },
  };

  return (
    <div style={{ overflow: 'hidden', width: 'fit-content' }}>
      {interests
        .map(
          interest =>
            !!interestIndex?.[interest] && (
              <OverlayTrigger
                overlay={
                  <Tooltip>{interestIndex?.[interest]?.tooltip}</Tooltip>
                }
              >
                <Image
                  alt=""
                  style={{ width: '25px' }}
                  src={interestIndex?.[interest]?.image}
                />
              </OverlayTrigger>
            ),
        )
        .filter(a => a)}
    </div>
  );
};

const enumerate = count => {
  return '0'
    .repeat(count)
    .split('')
    .map((_, index) => index);
};

const getClosestElement = selector => {
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const nearest = Array.from(document.querySelectorAll(selector))
    .map(element => {
      const top = element.getBoundingClientRect().top ?? 0;
      const bottom = element.getBoundingClientRect().bottom ?? 0;
      const distance = Math.min(
        Math.abs(0 - top),
        Math.abs(windowHeight - bottom),
      );

      return {
        distance,
        index: element.getAttribute('data-page-index') ?? 0,
      };
    })
    .sort((a, b) => a.distance - b.distance)?.[0];

  return !!nearest && nearest?.distance <= windowHeight ? nearest : null;
};

const getColorBasedOnPropensity = propensity => {
  if (propensity >= 0.9) return '5';
  else if (propensity >= 0.75) return '4';
  else if (propensity >= 0.5) return '3';
  else if (propensity >= 0.25) return '2';
  else return '1';
};

const getFinalStep = path => {
  const steps = path.split('.');
  return steps.slice(-1)?.[0];
};

const getIdeologyLabel = ideology => {
  if (!ideology) return '';
  else
    return [
      'Conservative',
      'Conservative Leaning',
      'Unaffiliated / Moderate',
      'Liberal Leaning',
      'Liberal',
    ][ideology - 1];
};

const getObjectValue = (object, path) => {
  const subObject = getSubObject(object, path);
  return subObject[getFinalStep(path)];
};

const getObjectDateValue = (object, path) => {
  const value = getObjectValue(object, path);

  if (!!value) return new Date(value).toISOString().split('T')[0];
};

const getPriorityIcon = priority => {
  const meta = {
    C_High: { color: 'danger', tooltip: 'Priority High' },
    B_Medium: { color: 'warning', tooltip: 'Priority Medium' },
    A_Low: { color: 'success', tooltip: 'Priority Low' },
    undefined: { color: 'dark', tooltip: 'Priority Undefined' },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[priority]?.tooltip}</Tooltip>}>
      <span>
        <TbCircleFilled className={`text-${meta?.[priority]?.color}`} />
      </span>
    </OverlayTrigger>
  );
};

const getProcessedTemplate = (templates, use, row) => {
  let content =
    templates
      .map(template =>
        template.use === use && template.isActive ? template.content : null,
      )
      .filter(a => a !== null)?.[0] ?? '';

  Object.keys(row).forEach(key => {
    const regex = new RegExp(`\\[\\[${key}\\]\\]`, 'gi');
    content = content.replace(regex, row[key]);
  });

  return encodeURIComponent(content);
};

const getSMSURL = (phone, message) => `sms:${phone};?&body=${message}`;

const getStatusPill = status => {
  const meta = {
    inProgress: {
      bgColor: 'primary',
      fontColor: 'light',
      label: 'In Progress',
      tooltip: 'In Progress',
    },
    lost: {
      bgColor: 'danger',
      fontColor: 'light',
      label: 'Lost',
      tooltip: 'Lost',
    },
    uncontacted: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Uncontacted',
      tooltip: 'Uncontacted',
    },
    won: {
      bgColor: 'success',
      fontColor: 'light',
      label: 'Won',
      tooltip: 'Won',
    },
    undefined: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Undefined Status',
      tooltip: 'Undefined Status',
    },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[status]?.tooltip}</Tooltip>}>
      <Badge bg={meta?.[status]?.bgColor} text={meta?.[status]?.fontColor}>
        {meta?.[status]?.label}
      </Badge>
    </OverlayTrigger>
  );
};

const getSubObject = (object, path) => {
  const steps = path.split('.');
  let subObject = object;

  while (steps.length > 1) {
    const step = steps.shift();

    if (!(step in subObject)) subObject[step] = {};
    subObject = subObject[step];
  }

  return subObject;
};

const getTallestPageHeight = () => {
  /*Array.from(document.querySelectorAll(`[data-page="true"]`)).forEach(
    page => ((page as HTMLElement).style.height = 'auto'),
  );*/

  return Array.from(document.querySelectorAll(`[data-page="true"]`))
    .map(page => page.offsetHeight)
    .sort()
    .reverse()?.[0];
};

const isGapInRange = (data, idField, min, max) => {
  let priorRowIndex = -1;
  let gapFound = false;

  for (let c = min; c <= max; c++) {
    const rowIndex = Number(
      document
        .getElementById(`plan-view-row-${data?.[c]?.[idField]}`)
        ?.getAttribute('data-index'),
    );

    if (c > min && rowIndex - priorRowIndex > 1) gapFound = true;

    priorRowIndex = rowIndex;
  }

  return gapFound;
};

const syncPageHeights = pagesLoaded => {
  const tallestHeight = getTallestPageHeight();

  const pages = document.querySelectorAll(`[data-page="true"]`);

  Array.from(pages).forEach((page, index) => {
    if (!pagesLoaded?.[index]) page.style.height = `${tallestHeight}px`;
    else page.style.height = 'auto';
  });
};

export {
  convertFlags,
  displayInterests,
  enumerate,
  getClosestElement,
  getColorBasedOnPropensity,
  getFinalStep,
  getIdeologyLabel,
  getObjectValue,
  getObjectDateValue,
  getPriorityIcon,
  getProcessedTemplate,
  getSMSURL,
  getStatusPill,
  getSubObject,
  getTallestPageHeight,
  isGapInRange,
  syncPageHeights,
};
