import Legalese from 'app/components/Legalese';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const FAQ = () => {
  return (
    <Row className="text-dark position-relative mt-5">
      <Col lg={1} />
      <Col lg={10}>
        <p>
          <strong>Q. What is Visitor Log?</strong>
        </p>
        <p>
          A. Visitor Log is a website visitor identification technology from
          QuantumRed.
        </p>
        <p>
          <strong>Q. Why should I use Visitor Log?</strong>
        </p>
        <p>
          A. Over 90% of visitors to your website do not give you their email or
          phone number. We identify these anonymous visitors and provide you
          with contact information so you can reach out to them so you do not
          miss another potential supporter, donor, or customer.
        </p>
        <p>
          <strong>Q. How does Visitor Log work?</strong>
        </p>
        <p>
          A. Sign up and provide payment information (credit card). We give you
          a unique pixel to put your on your website. You receive daily emails
          with your website visitor records (either basic or enhanced)
        </p>
        <p>
          <strong>Q. Who owns the data Visitor Log provides?</strong>
        </p>
        <p>A. You do. We provide the tool, but the data belongs to you. </p>
        <p>
          <strong>Q. How does Visitor Log identify webpage visitors?</strong>
        </p>
        <p>
          A. This is our secret sauce, but here’s the basics: Your website
          visitors are identified using the “digital fingerprint” of the
          visitor. This may include their IP address, device ID, cookies, or
          other other identifying information. That digital fingerprint is then
          matched against a series of datasets using an identity resolution
          graph. Depending on the results of that match, that data may be
          re-matched against additional proprietary databases of QR partners and
          affiliates in order to provide you with the most comprehensive user
          identification available.
        </p>
        <p>
          <strong>Q. What is an Identity Graph?</strong>
        </p>
        <p>
          A. An identity graph is a collection of data that links multiple
          identifiers or touchpoints of a specific user or entity across various
          devices, channels, and platforms. It helps companies and marketers to
          create a unified view of an individual or a household across different
          interactions, devices, and platforms. By consolidating data from
          various sources, an identity graph provides a more comprehensive and
          accurate representation of an individual's behavior and preferences.
        </p>
        <p>
          <strong>
            Q. Will Visitor Log identify every visitor to my website?
          </strong>
        </p>
        <p>
          A. No. Visitor Log will not identify every visitor. Visitors may
          remain anonymous or unidentified for one of two main reasons.
        </p>
        <p>
          First, visitors may not be identifiable because the visitor took steps
          to block or minimize their digital fingerprint. These steps may
          include adjusting their browser settings for greater privacy, using
          the services of a Virtual Private Network (VPN), anonymizer, or
          tunnelers (such as Tor), or using any number of other similar
          technologies.
        </p>
        <p>
          Second, visitors may provide a digital fingerprint but may not be
          identifiable because we are unable to match them to an Identity Graph
          or other identifying database.
        </p>
        <p>
          The percent of your website visitors that Visitor Log can identify
          will depend entirely on how your unique visitors fall into the two
          categories above.
        </p>
        <p>
          <strong>Q. Are my identified website visitor records unique?</strong>
        </p>
        <p>
          A. Generally, yes. When an identified record is listed in your Visitor
          Log, this is treated as a unique record. Should that person visit your
          website again, you will most likely see the same unique record's visit
          count update. The primary exception is when a visitor's subsequent
          visits do not match the data provided in their initial visit (e.g.,
          they have a different email address/ phone number identified that was
          not matched for the initial visit.) in which case the visitor may show
          up as a new visitor.
        </p>
        <p>
          <strong>
            Q. What about privacy laws? How do my visitors give consent?
          </strong>
        </p>
        <p>
          A: Visitor Log requires your site to have a consent string for your
          customers. We can provide you with initial guidance as part of your
          onboarding. A consent string is a string of characters that encodes
          information about user consent for the collection and usage of
          personal data for online advertising purposes. This is common practice
          in digital marketing. Second, we recommend you have a modern data
          privacy policy in place that includes your use of tools such as
          Visitor Log. We can’t give legal advice, but standard modern privacy
          policies typically contain: 1) the types of data you collect, 2) the
          purpose of collection, 3) your data storage and retention policies, 4)
          your data sharing policies, 5) the rights of visitors to your site
          (e.g., right to access, correct, delete, restrict the use of their
          data, or opt out of data collection), 6) your security practices, and
          7) your process for making changes to your privacy policy. Laws vary
          by jurisdiction and you are responsible to ensure compliance with the
          laws relevant to you. Consult an attorney as needed. We provide you
          with a tool to collect data (a website visitor identification pixel)
          but the tool is used and the data you obtain is collected on your
          site, for your use, is governed by your privacy policy, and the laws
          applicable to your jurisdiction.
        </p>
        <p>
          <strong>
            Q. Is the data I received from Visitor Log TCPA compliant?
          </strong>
        </p>
        <p>
          A. TCPA (Telephone Consumer Protection Act) compliance depends on how
          the data is collected and if the individuals included in the data gave
          you their prior express consent for telemarketing calls or texts to be
          made to them. Visitor Log does not know the relationship between you
          and a visitor to your site, or if consent was given to you elsewhere.
          TCPA consent is not given through Visitor Log. As a general rule, if
          your only knowledge of a visitor is from Visitor Log, the data
          provided can only be leveraged for your enrichment, targeting and
          matching of your own data files, unless you specifically obtain the
          permission from the user elsewhere. Visitor Log only provides you with
          a technical tool, the compliant use of the tool and the data you
          obtain from the tool are your responsibility. Consult an attorney for
          your unique situation.
        </p>
        <p>
          <strong>
            Q. Where should I install the tracking pixel (tag/iframe) on my
            website?
          </strong>
        </p>
        <p>
          A. We’ll help you with this. The pixel should be installed in a global
          location in your site code. This could be a &lt;head&gt; or
          &lt;body&gt; tag element, or placed and deployed using Google Tag
          Manager (GTM). Successful installation would show the code snippet
          being visible when inspecting the code in the browser.
        </p>
        <p>
          <strong>
            Q. Can I install the tracking pixel (tag/iframe) on a single or
            specific web page?
          </strong>
        </p>
        <p>
          A. Yes, the tracking pixel can be installed on a single web page to
          track only that specific page's web traffic. When the pixel is
          installed to a specific page, not in a globally applied header-code,
          the pixel will track the data for the single associated page only.
        </p>
        <Legalese marginTop />
      </Col>
    </Row>
  );
};

export default FAQ;
