import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripePaymentElementChangeEvent,
  PaymentMethod,
} from '@stripe/stripe-js';
import { CPaymentIntent } from 'api/interfaces';
import {
  confirmPaymentIntent,
  getPaymentIntent,
  listPaymentMethods,
} from 'api/stripe.service';
import { updateOrganizationSubscription } from 'api/subscription.service';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthUser } from 'store/authentication/authentication.selector';
import FooterStripe from '../FooterStripe';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

interface CheckoutFormProps {
  paymentInfo: {
    stripeCustomerID: string;
    stripeBasicMeteredSubscriptionItemId: string;
    stripeEnhancedMeteredSubscriptionItemId: string;
    meteredSubscriptionItemId: string;
    paymentIntent: CPaymentIntent;
  };
  plan: number;
  agreedToToS: boolean;
}

const CheckoutForm: FC<CheckoutFormProps> = ({
  paymentInfo: {
    stripeCustomerID,
    stripeBasicMeteredSubscriptionItemId,
    stripeEnhancedMeteredSubscriptionItemId,
    paymentIntent,
  },
  plan,
  agreedToToS,
}) => {
  const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(false);
  const userLogged = useSelector(getAuthUser);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const stripe = useStripe()!;
  const elements = useElements()!;

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [pMethod, setPMethod] = React.useState('');

  const handleRadioChange = pMethod => {
    setPMethod(pMethod);
  };

  const afterSucceeded = async () => {
    await updateOrganizationSubscription(
      userLogged!.email,
      plan,
      stripeCustomerID,
      stripeBasicMeteredSubscriptionItemId,
      stripeEnhancedMeteredSubscriptionItemId,
    );
    setError('');
    setSucceeded(true);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    setError('');
    elements.submit();
    try {
      const payload = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent.client_secret || '',
        redirect: 'if_required',
        confirmParams: {
          save_payment_method: savePaymentMethod,
          receipt_email: userLogged?.email || '',
        },
      });
      if (payload.error) {
        const paymentIntentResponse = await getPaymentIntent(paymentIntent.id);
        if (paymentIntentResponse?.status === 'succeeded') {
          await afterSucceeded();
        } else {
          // eslint-disable-next-line no-throw-literal
          throw new Error(`Payment failed ${payload?.error?.message}`);
        }
      } else {
        await afterSucceeded();
      }
    } catch (error) {
      setProcessing(false);
      setSucceeded(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(`Unexpected error ${error}`);
      }
    }
  };

  const payWithPaymentSelected = async () => {
    setProcessing(true);
    try {
      const result = await confirmPaymentIntent(paymentIntent.id, pMethod);
      if (result.status === 'succeeded') {
        await afterSucceeded();
      } else {
        throw new Error(`Payment failed`);
      }
    } catch (error) {
      setSucceeded(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(`Unexpected error ${error}`);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleChange = (event: StripePaymentElementChangeEvent) => {
    setDisabled(event.empty);
  };

  useEffect(() => {
    const succeeded = paymentIntent.status === 'succeeded';
    setSucceeded(succeeded);
    listPaymentMethods(paymentIntent.customer, { type: 'card' }).then(
      listResponse => {
        setPaymentMethods(listResponse.data);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  useEffect(() => {
    if (succeeded) {
      navigate('/log');
    }
  }, [navigate, succeeded]);

  return (
    <Card className="bg-light text-dark">
      <Card.Body>
        <Card.Header className="text-end">
          <Card.Title>
            Website Visitor Log ({plan === 1 ? 'Basic' : 'Enhanced'} Data)
          </Card.Title>
          <Card.Text>
            <small>
              $249 set-up, $149/month & {plan === 1 ? '$0.25' : '$1.00'} per
              record
            </small>
          </Card.Text>
          <Card.Title className="fw-bold">
            ${paymentIntent.amount / 100}
          </Card.Title>
        </Card.Header>
        <div>
          {succeeded ? (
            <div>
              <div>
                <Alert variant="success">Payment success</Alert>{' '}
              </div>
            </div>
          ) : (
            <div>
              {!!paymentMethods.length && (
                <Form.Group className="mt-3">
                  <Form.Label>Saved Payment Methods</Form.Label>
                  {paymentMethods.map(paymentMethod => (
                    <div key={paymentMethod.id}>
                      <Form.Check
                        type="radio"
                        value={paymentMethod.id}
                        checked={paymentMethod.id === pMethod}
                        onChange={() => handleRadioChange(paymentMethod.id)}
                        label={
                          <>
                            ********{paymentMethod.card?.last4}{' '}
                            {paymentMethod.card?.brand}{' '}
                            <strong>
                              Exp {paymentMethod.card?.exp_month}/
                              {paymentMethod.card?.exp_year}
                            </strong>
                            {pMethod === paymentMethod.id && (
                              <Button
                                disabled={
                                  processing || disabled || !agreedToToS
                                }
                                onClick={payWithPaymentSelected}
                                id="submit"
                                className="ms-3 text-dark"
                              >
                                Pay
                              </Button>
                            )}
                          </>
                        }
                      />
                    </div>
                  ))}
                </Form.Group>
              )}

              <PaymentElement
                options={{
                  defaultValues: {
                    billingDetails: {
                      email: userLogged?.email,
                    },
                  },
                }}
                className="Custom-PaymentElement"
                onChange={handleChange}
              />
              {processing ? (
                <div className="text-center mt-3">Processing...</div>
              ) : (
                <>
                  <Button
                    disabled={processing || disabled || !agreedToToS}
                    className="w-25 mt-3  text-dark"
                    onClick={handleSubmit}
                  >
                    Pay
                  </Button>
                  <Form.Check
                    label="Save Payment Method"
                    checked={savePaymentMethod}
                    onChange={() => setSavePaymentMethod(!savePaymentMethod)}
                  />
                </>
              )}
              {error && <Alert variant="danger">{error}</Alert>}
            </div>
          )}
          <FooterStripe />
        </div>
      </Card.Body>
    </Card>
  );
};

export default CheckoutForm;
