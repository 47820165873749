import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { registerUser } from 'api/auth.service';
import GoogleLoginButton from '../GoogleLoginButton';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const SignUp = ({
  googleClientId,
  googleClientSecret,
  googleOAuthAccessTokenURL,
  googleOAuthTokenValidationURL,
  googleRedirectURL,
  googleScope,
  includeFirstName = false,
  includeLastName = false,
  includeOrganization = false,
  includePassword = false,
  includePhone = false,
  includeTitle = false,
  legalese,
  signupHeader,
  signupCTA,
  onToggle,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [firstName, setFirstNae] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const onSubmit = async e => {
    e.preventDefault();

    if (includeFirstName && !firstName) setError('First name is required.');
    else if (includeLastName && !lastName) setError('Last name is required.');
    else if (includePhone && !phone) setError('Phone is required.');
    else if (includeOrganization && !organizationName)
      setError('Organization/Campaign is required.');
    else if (includePassword && !password) setError('Password is required.');
    else if (includeTitle && !title) setError('Title/Role is required.');
    else if (!email) setError('Email is required.');
    else
      registerUser({
        email,
        firstName,
        lastName,
        organizationName,
        password,
        phone,
        title,
        active: false,
        isFederated: false,
        organizationId: 0,
      })
        .then(response => {
          if (response) {
            toast.success(
              !includePassword
                ? 'Check your email for the temporary password.'
                : 'Account created successfully! Please login.',
            );
            navigate('/login');
          }
        })
        .catch(error => {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.error);
          }
        })
        .finally(() => {});
  };

  return (
    <Row>
      <Col md={3}></Col>
      <Col className="bordered p-5">
        <div className="text-center fs-4">{signupHeader}</div>
        <div className="text-center mb-3">
          Already have an account?&nbsp;
          <Button
            className="align-text-top rounded-0 border-0 p-0 m-0 text-decoration-none"
            variant="link"
            onClick={onToggle}
          >
            Log-in
          </Button>
        </div>
        {
          <GoogleLoginButton
            behaviorType="SignUp"
            buttonText="Sign up with Google"
            googleClientId={googleClientId}
            googleClientSecret={googleClientSecret}
            googleOAuthAccessTokenURL={googleOAuthAccessTokenURL}
            googleOAuthTokenValidationURL={googleOAuthTokenValidationURL}
            googleRedirectURL={googleRedirectURL}
            googleScope={googleScope}
          />
        }
        <Form onSubmit={onSubmit}>
          {includeFirstName && (
            <Form.Control
              className="mb-3"
              type="text"
              value={firstName}
              placeholder="First"
              onChange={e => setFirstNae(e.target.value)}
            />
          )}
          {includeLastName && (
            <Form.Control
              className="mb-3"
              type="text"
              value={lastName}
              placeholder="Last"
              onChange={e => setLastName(e.target.value)}
            />
          )}
          {includePhone && (
            <Form.Control
              className="mb-3"
              type="phone"
              value={phone}
              placeholder="Phone"
              onChange={e => setPhone(e.target.value)}
            />
          )}
          {includeOrganization && (
            <Form.Control
              className="mb-3"
              type="text"
              value={organizationName}
              placeholder="Your Organization/Campaign"
              onChange={e => setOrganizationName(e.target.value)}
            />
          )}
          {includeTitle && (
            <Form.Control
              className="mb-3"
              type="text"
              value={title}
              placeholder="Your Title/Role"
              onChange={e => setTitle(e.target.value)}
            />
          )}
          <Form.Control
            className="mb-3"
            type="email"
            value={email}
            placeholder="Your Email/Log-in"
            onChange={e => setEmail(e.target.value)}
          />
          {includePassword && (
            <Form.Control
              className="mb-3"
              type="password"
              value={password}
              placeholder="Set a Password"
              onChange={e => setPassword(e.target.value)}
            />
          )}
          {!!error && (
            <Alert variant="danger" className="mb-3">
              {error}
            </Alert>
          )}
          <Button type="submit" className="w-100">
            {signupCTA}
          </Button>
        </Form>
        <div>{legalese}</div>
      </Col>
      <Col md={3}></Col>
    </Row>
  );
};

export default SignUp;
