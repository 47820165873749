import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Login from './Login';
import SignUp from './Signup';
import BasicMotion from './BasicMotion';

const LoginSignup = ({
  className,
  legalese,
  loginCTA,
  loginHeader,
  loginText,
  googleClientId,
  googleClientSecret,
  googleOAuthAccessTokenURL,
  googleOAuthTokenValidationURL,
  googleRedirectURL,
  googleScope,
  includeFirstName = false,
  includeLastName = false,
  includeOrganization = false,
  includePassword = false,
  includePhone = false,
  includeTitle = false,
  signupCTA,
  signupHeader,
  signupText,
  useAuthSliceSlice,
}) => {
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const onToggle = () => {
    if (showLogin) {
      setShowLogin(false);
      setShowSignup(true);
    } else {
      setShowLogin(true);
      setShowSignup(false);
    }
  };
  return (
    <div className={className}>
      {showLogin && (
        <BasicMotion>
          <Login
            useAuthSliceSlice={useAuthSliceSlice}
            onToggle={onToggle}
            legalese={legalese}
            loginHeader={loginHeader}
            loginCTA={loginCTA}
            googleClientId={googleClientId}
            googleClientSecret={googleClientSecret}
            googleOAuthAccessTokenURL={googleOAuthAccessTokenURL}
            googleOAuthTokenValidationURL={googleOAuthTokenValidationURL}
            googleRedirectURL={googleRedirectURL}
            googleScope={googleScope}
          />
        </BasicMotion>
      )}
      {showSignup && (
        <BasicMotion>
          <SignUp
            onToggle={onToggle}
            includeFirstName={includeFirstName}
            includeLastName={includeLastName}
            includeOrganization={includeOrganization}
            includePassword={includePassword}
            includePhone={includePhone}
            includeTitle={includeTitle}
            legalese={legalese}
            signupHeader={signupHeader}
            signupCTA={signupCTA}
            googleClientId={googleClientId}
            googleClientSecret={googleClientSecret}
            googleOAuthAccessTokenURL={googleOAuthAccessTokenURL}
            googleOAuthTokenValidationURL={googleOAuthTokenValidationURL}
            googleRedirectURL={googleRedirectURL}
            googleScope={googleScope}
          />
        </BasicMotion>
      )}
      {!showLogin && !showSignup && (
        <BasicMotion>
          <Row className="row-equal-height">
            <Col lg={6} className="mb-3 mb-md-0 p-lg-5">
              <Row className="h-100">
                <Col className="bordered p-5">
                  <Card className="h-100">
                    <Card.Body>
                      <Card.Title>{signupHeader}</Card.Title>
                      <Card.Text>{signupText}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button onClick={() => setShowSignup(true)}>
                        {signupCTA}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="p-lg-5">
              <Row className="h-100">
                <Col className="bordered p-5">
                  <Card className="h-100">
                    <Card.Body>
                      <Card.Title>{loginHeader}</Card.Title>
                      <Card.Text>{loginText}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button onClick={() => setShowLogin(true)}>
                        {loginCTA}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>{legalese}</Col>
          </Row>
        </BasicMotion>
      )}
    </div>
  );
};

export default LoginSignup;
