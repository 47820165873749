const cellTemplateList = [
  /*{
    field: 'enhanced',
    header: 'enhanced',
    showInDesktop: true,
    contentHandler: row => (row?.enhanced ? 'enhanced' : 'basic'),
  },*/
  {
    field: 'Websites',
    header: 'Websites',
    showInDesktop: true,
    contentHandler: row => row?.Websites,
  },
  {
    field: 'LastActivityDate',
    header: 'Last Activity Date',
    showInDesktop: true,
    contentHandler: row => row?.LastActivityDate,
  },
  {
    field: 'RecordType',
    header: 'Record Type',
    showInDesktop: true,
    contentHandler: row => row?.RecordType,
  },
  {
    field: 'IndividualVisits',
    header: 'Individual Visits',
    showInDesktop: true,
    contentHandler: row => row?.IndividualVisits,
  },
  {
    field: 'CompanyVisits',
    header: 'Company Visits',
    showInDesktop: true,
    contentHandler: row => row?.CompanyVisits,
  },
  {
    field: 'Email',
    header: 'Email',
    showInDesktop: true,
    contentHandler: row => row?.Email,
  },
  {
    field: 'FirstName',
    header: 'First Name',
    showInDesktop: true,
    contentHandler: row => row?.FirstName,
  },
  {
    field: 'LastName',
    header: 'Last Name',
    showInDesktop: true,
    contentHandler: row => row?.LastName,
  },
  {
    field: 'Gender',
    header: 'Gender',
    showInDesktop: true,
    contentHandler: row => row?.Gender,
  },
  {
    field: 'AgeRange',
    header: 'Age Range',
    showInDesktop: true,
    contentHandler: row => row?.AgeRange,
  },
  {
    field: 'IncomeRange',
    header: 'Income Range',
    showInDesktop: true,
    contentHandler: row => row?.IncomeRange,
  },
  {
    field: 'LinkedIn',
    header: 'LinkedIn',
    showInDesktop: true,
    contentHandler: row => row?.LinkedIn,
  },
  {
    field: 'JobTitle',
    header: 'Job Title',
    showInDesktop: true,
    contentHandler: row => row?.JobTitle,
  },
  {
    field: 'SeniorityLevel',
    header: 'Seniority Level',
    showInDesktop: true,
    contentHandler: row => row?.SeniorityLevel,
  },
  {
    field: 'Department',
    header: 'Department',
    showInDesktop: true,
    contentHandler: row => row?.Department,
  },
  {
    field: 'CompanyName',
    header: 'Company Name',
    showInDesktop: true,
    contentHandler: row => row?.CompanyName,
  },
  {
    field: 'CompanyDomain',
    header: 'Company Domain',
    showInDesktop: true,
    contentHandler: row => row?.CompanyDomain,
  },
  {
    field: 'CompanyLinkedIn',
    header: 'Company LinkedIn',
    showInDesktop: true,
    contentHandler: row => row?.CompanyLinkedIn,
  },
  {
    field: 'CompanyRevenue',
    header: 'Company Revenue',
    showInDesktop: true,
    contentHandler: row => row?.CompanyRevenue,
  },
  {
    field: 'CompanyEmployeeCount',
    header: 'Company Employee Count',
    showInDesktop: true,
    contentHandler: row => row?.CompanyEmployeeCount,
  },
  {
    field: 'CompanyPrimaryIndustry',
    header: 'Company Primary Industry',
    showInDesktop: true,
    contentHandler: row => row?.CompanyPrimaryIndustry,
  },
  {
    field: 'CompanySIC1',
    header: 'Company SIC1',
    showInDesktop: true,
    contentHandler: row => row?.CompanySIC1,
  },
  {
    field: 'CompanySIC2',
    header: 'Company SIC2',
    showInDesktop: true,
    contentHandler: row => row?.CompanySIC2,
  },
  {
    field: 'CompanySIC3',
    header: 'Company SIC3',
    showInDesktop: true,
    contentHandler: row => row?.CompanySIC3,
  },
  {
    field: 'MobilePhone1',
    header: 'Mobile Phone 1',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhone1,
  },
  {
    field: 'MobilePhone2',
    header: 'Mobile Phone 2',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhone2,
  },
  {
    field: 'MobilePhone3',
    header: 'Mobile Phone 3',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhone3,
  },
  {
    field: 'PersonalPhone1',
    header: 'Personal Phone 1',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhone1,
  },
  {
    field: 'PersonalPhone2',
    header: 'Personal Phone 2',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhone2,
  },
  {
    field: 'PersonalPhone3',
    header: 'Personal Phone 3',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhone3,
  },
  {
    field: 'DirectNumber1',
    header: 'Direct Number 1',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumber1,
  },
  {
    field: 'DirectNumber2',
    header: 'Direct Number 2',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumber2,
  },
  {
    field: 'DirectNumber3',
    header: 'Direct Number 3',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumber3,
  },
  {
    field: 'CompanyPhone1',
    header: 'Company Phone 1',
    showInDesktop: true,
    contentHandler: row => row?.CompanyPhone1,
  },
  {
    field: 'CompanyPhone2',
    header: 'Company Phone 2',
    showInDesktop: true,
    contentHandler: row => row?.CompanyPhone2,
  },
  {
    field: 'CompanyPhone3',
    header: 'Company Phone 3',
    showInDesktop: true,
    contentHandler: row => row?.CompanyPhone3,
  },
  {
    field: 'PersonalCity',
    header: 'Personal City',
    showInDesktop: true,
    contentHandler: row => row?.PersonalCity,
  },
  {
    field: 'PersonalState',
    header: 'Personal State',
    showInDesktop: true,
    contentHandler: row => row?.PersonalState,
  },
  {
    field: 'PersonalZIP',
    header: 'Personal ZIP',
    showInDesktop: true,
    contentHandler: row => row?.PersonalZIP,
  },
  {
    field: 'PersonalZIP4',
    header: 'Personal ZIP 4',
    showInDesktop: true,
    contentHandler: row => row?.PersonalZIP4,
  },
  {
    field: 'ProfessionalCity',
    header: 'Professional City',
    showInDesktop: true,
    contentHandler: row => row?.ProfessionalCity,
  },
  {
    field: 'ProfessionalState',
    header: 'Professional State',
    showInDesktop: true,
    contentHandler: row => row?.ProfessionalState,
  },
  {
    field: 'ProfessionalZIP',
    header: 'Professional ZIP',
    showInDesktop: true,
    contentHandler: row => row?.ProfessionalZIP,
  },
  {
    field: 'ProfessionalZIP4',
    header: 'Professional ZIP 4',
    showInDesktop: true,
    contentHandler: row => row?.ProfessionalZIP4,
  },
  {
    field: 'CompanyAddress1',
    header: 'Company Address 1',
    showInDesktop: true,
    contentHandler: row => row?.CompanyAddress1,
  },
  {
    field: 'CompanyAddress2',
    header: 'Company Address 2',
    showInDesktop: true,
    contentHandler: row => row?.CompanyAddress2,
  },
  {
    field: 'CompanyCity',
    header: 'Company City',
    showInDesktop: true,
    contentHandler: row => row?.CompanyCity,
  },
  {
    field: 'CompanyState',
    header: 'Company State',
    showInDesktop: true,
    contentHandler: row => row?.CompanyState,
  },
  {
    field: 'CompanyZIP',
    header: 'Company ZIP',
    showInDesktop: true,
    contentHandler: row => row?.CompanyZIP,
  },
  {
    field: 'CompanyZIP4',
    header: 'Company ZIP 4',
    showInDesktop: true,
    contentHandler: row => row?.CompanyZIP4,
  },
  {
    field: 'EmailLastSeen',
    header: 'EmailLast Seen',
    showInDesktop: true,
    contentHandler: row => row?.EmailLastSeen,
  },
  {
    field: 'MobilePhoneValidationStatus1',
    header: 'Mobile Phone Validation Status 1',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneValidationStatus1,
  },
  {
    field: 'MobilePhoneValidationStatus2',
    header: 'Mobile Phone Validation Status 2',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneValidationStatus2,
  },
  {
    field: 'MobilePhoneValidationStatus3',
    header: 'Mobile Phone Validation Status 3',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneValidationStatus3,
  },
  {
    field: 'MobilePhoneLastSeen1',
    header: 'Mobile Phone Last Seen 1',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneLastSeen1,
  },
  {
    field: 'MobilePhoneLastSeen2',
    header: 'Mobile Phone Last Seen 2',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneLastSeen2,
  },
  {
    field: 'MobilePhoneLastSeen3',
    header: 'Mobile Phone Last Seen 3',
    showInDesktop: true,
    contentHandler: row => row?.MobilePhoneLastSeen3,
  },
  {
    field: 'PersonalPhoneValidationStatus1',
    header: 'Personal Phone Validation Status 1',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneValidationStatus1,
  },
  {
    field: 'PersonalPhoneValidationStatus2',
    header: 'Personal Phone Validation Status 2',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneValidationStatus2,
  },
  {
    field: 'PersonalPhoneValidationStatus3',
    header: 'Personal Phone Validation Status 3',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneValidationStatus3,
  },
  {
    field: 'PersonalPhoneLastSeen1',
    header: 'Personal Phone Last Seen 1',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneLastSeen1,
  },
  {
    field: 'PersonalPhoneLastSeen2',
    header: 'Personal Phone Last Seen 2',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneLastSeen2,
  },
  {
    field: 'PersonalPhoneLastSeen3',
    header: 'Personal Phone Last Seen 3',
    showInDesktop: true,
    contentHandler: row => row?.PersonalPhoneLastSeen3,
  },
  {
    field: 'DirectNumberValidationStatus1',
    header: 'Direct Number Validation Status 1',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberValidationStatus1,
  },
  {
    field: 'DirectNumberValidationStatus2',
    header: 'Direct Number Validation Status 2',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberValidationStatus2,
  },
  {
    field: 'DirectNumberValidationStatus3',
    header: 'Direct Number Validation Status 3',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberValidationStatus3,
  },
  {
    field: 'DirectNumberLastSeen1',
    header: 'Direct Number Last Seen 1',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberLastSeen1,
  },
  {
    field: 'DirectNumberLastSeen2',
    header: 'Direct Number Last Seen 2',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberLastSeen2,
  },
  {
    field: 'DirectNumberLastSeen3',
    header: 'Direct Number Last Seen 3',
    showInDesktop: true,
    contentHandler: row => row?.DirectNumberLastSeen3,
  },
];

export { cellTemplateList };
