import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import { getOneOrganization } from 'api/organization.service';
import Legalese from 'app/components/Legalese';
import { CheckSquare, Square } from 'react-bootstrap-icons';
import PaymentHistory from 'app/components/PaymentHistory';
import constants from '../../config/constants';

export function SubscriptionPlanPage(props) {
  const currentUser = useSelector(getAuthUser);
  const [organization, setOrganization] = React.useState<any>(null);

  const planCard = (
    title,
    price,
    description,
    buttonHRef,
    buttonLabel,
    buttonDisable = false,
    checked = false,
    isLast = false,
  ) => (
    <Row className={!isLast ? 'mb-5' : ''}>
      <Col xs={2} sm={1}>
        <div className={`checkbox ${checked}`}>
          {checked ? <CheckSquare size={'100%'} /> : <Square size={'100%'} />}
        </div>
      </Col>
      <Col xs={10} sm={9}>
        <div className="fs-5">{title}</div>
        <div className="fs-6 fw-bold">{price}</div>
        <div className="fs-6">
          <small>{description}</small>
        </div>
      </Col>
      <Col lg={2}>
        <Button className="w-100" disabled={buttonDisable} href={buttonHRef}>
          {buttonLabel}
        </Button>
      </Col>
    </Row>
  );

  React.useEffect(() => {
    if (currentUser?.organizationId)
      getOneOrganization({ Organization_ID: currentUser?.organizationId }).then(
        response => setOrganization(response),
      );
  }, [currentUser?.organizationId]);

  return (
    <Row className="my-5">
      <Col md={2}></Col>
      <Col className="bordered p-5">
        <div className="fs-4 text-center mb-5">
          Welcome, {currentUser?.firstName}
        </div>

        {planCard(
          'Website Visitor Log (Basic Data)',
          '$249 set-up, $149/month & $0.25 per record',
          'Know who visited your website. Get a visitor’s Name, Email, Address, Phone Numbers, Location, Site Visits',
          `/subscribe?plan=1`,
          organization?.plan === 1 ? 'Current Plan' : 'Purchase Online',
          organization?.plan === 1,
          organization?.plan === 1,
        )}
        {planCard(
          'Website Visitor Log (Enhanced Data)',
          '$249 set-up, $149/month & $1 per record',
          'Know who visited your website: Get Basic Data plus wealth/income, political ideology, likelihood to donate, capacity to donate, and more',
          `/subscribe?plan=2`,
          'Coming Soon',
          true,
        )}
        {planCard(
          'DRE New Donor Discovery and Outreach Platform',
          'Starting at $50/mo & $1 per record',
          'Software platform to discover new hard-to-find, first-time, wealthy donors passionate about your cause',
          `mailTo:${constants.POINT_OF_CONTACT()}`,
          'Contact Us',
        )}
        {planCard(
          'Benjamin Platform',
          'Starting at $150/mo plus multiple tiers and additional services depending on your needs',
          'Get the big money. Large donor CRM and digital donor bundling management and outreach platform',
          `mailTo:${constants.POINT_OF_CONTACT()}`,
          'Contact Us',
        )}
        {planCard(
          'QuantumRed Consulting',
          'Starting at $1000',
          'We provide expertise to nonprofits and campaigns in AI, data science, software, and digital fundraising on a monthly retainer or outcome based pricing',
          `mailTo:${constants.POINT_OF_CONTACT()}`,
          'Contact Us',
        )}

        <PaymentHistory />

        <Legalese marginTop />
      </Col>
      <Col md={2}></Col>
    </Row>
  );
}
