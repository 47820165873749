import constants from 'app/config/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

const INTERNAL_SERVER_ERROR = 500;

const axiosInstance = axios.create({
  baseURL: `${constants.BASE_ENDPOINT()}/api`,
  headers: {
    'Session-Token': sessionStorage.getItem('Session-Token'),
    'Content-Type': 'application/json',
    'auth-domain': 'vlog',
    timeout: 1000,
  },
  // .. other options
});
const addURLparams = reqObj => {
  let newUrl = ``;
  Object.keys(reqObj).forEach((key, i) => {
    if (reqObj[key]) {
      if (i === 0) {
        newUrl += `?${encodeURIComponent(key)}=${encodeURIComponent(
          reqObj[key],
        )}`;
      } else {
        newUrl += `&${encodeURIComponent(key)}=${encodeURIComponent(
          reqObj[key],
        )}`;
      }
    }
  });
  return newUrl;
};

const postUtil = (url, data) =>
  axios({
    method: 'post',
    url,
    data,
  });

const putUtil = (url, data) =>
  axios({
    method: `put`,
    url,
    data,
  });

const patchUtil = (url, data) =>
  axios({
    method: `patch`,
    url,
    data,
  });

const getUtil = (url, data = null) =>
  axios({
    method: `get`,
    url,
    params: data,
  });

const deleteUtil = (url, data) =>
  axios({
    method: `delete`,
    url,
    data,
  });

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    let errorMsg = {};

    if (axios.isAxiosError(error)) {
      if (error.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error.response?.data.error);
      }
    }
    if (error.response.status === INTERNAL_SERVER_ERROR) {
      errorMsg = {
        status: error.response.status,
        message: error.response.statusText,
      };
    }
    return errorMsg;
  },
);

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers['Session-Token'] = sessionStorage.getItem('Session-Token');
    config.headers.authDomain = 'vlog';
    return config;
  },
  async error => {
    let errorMsg = {};
    if (error.response.status === INTERNAL_SERVER_ERROR) {
      errorMsg = {
        status: error.response.status,
        message: error.response.statusText,
      };
    }
    return errorMsg;
  },
);

const fetchData = async (key, urlParams, endpoint, method = `GET`, payload) => {
  const requestURL = `${endpoint}${addURLparams(urlParams)}`;
  try {
    const data =
      method === `GET`
        ? await getUtil(requestURL)
        : await postUtil(requestURL, payload);
    return data;
  } catch (error) {
    throw new Error(error, key);
  }
};

export { postUtil, getUtil, putUtil, patchUtil, deleteUtil, fetchData };

export default axiosInstance;
