import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { useSearchParams } from 'react-router-dom';
import constants from 'app/config/constants';
import { createPaymentIntent } from 'api/stripe.service';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import dayjs from 'dayjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import TOS from './TOS';
import Legalese from 'app/components/Legalese';

const StripePaymentPage = () => {
  const userLogged = useSelector(getAuthUser);
  const [searchParams] = useSearchParams();

  const [agreedToToS, setAgreedToToS] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const plan = Number(searchParams.get('plan') ?? '');
  const promise = loadStripe(constants.STRIPE_PUBLIC_KEY()!);

  useEffect(() => {
    if (!!userLogged?.email && !paymentInfo && !isLoading) {
      setIsLoading(true);

      createPaymentIntent({
        plan,
        organizationId: userLogged?.organizationId,
        customer: '',
        description: `Purchased ..., at ${dayjs().format()}`,
        customerPayload: {
          email: userLogged?.email || '',
          name: `${userLogged?.firstName || ''} ${userLogged?.lastName || ''}`,
          phone: '',
        },
        receipt_email: userLogged?.email,
        metadata: {
          email: userLogged?.email || '',
          organizationId: userLogged?.organizationId || '',
        },
      }).then(response => {
        setPaymentInfo(response);
        setIsLoading(false);
      });
    }
  }, [
    isLoading,
    paymentInfo,
    plan,
    userLogged?.email,
    userLogged?.firstName,
    userLogged?.lastName,
    userLogged?.organizationId,
  ]);

  if (!paymentInfo?.paymentIntent)
    return (
      <div className="mt-5 text-light text-center">
        <Spinner variant="dark" />
      </div>
    );
  return (
    <>
      <Row className="row-eq-height">
        <Col lg={1}></Col>
        <Col
          lg={6}
          className="overflow-auto"
          style={{ maxHeight: 'calc(100vh - 100px)' }}
        >
          <div className="fs-4 fw-bold text-center mb-3">
            Before continuing, please agree to the following terms of use.
          </div>

          {(userLogged?.firstName || userLogged?.lastName) && (
            <div className="fs-5 text-center">
              {`${userLogged?.firstName ?? ''} ${userLogged?.lastName ?? ''}`}
            </div>
          )}

          {!!userLogged?.title && (
            <div className="fs-5 text-center">{`${userLogged?.title}`}</div>
          )}

          {!!userLogged?.organizationName && (
            <div className="fs-5 text-center mb-3">
              {`${userLogged.organizationName}`}
            </div>
          )}

          <h5>
            <TOS
              agreedToToS={agreedToToS}
              onAgreement={() => setAgreedToToS(true)}
            />
          </h5>
        </Col>
        <Col lg={4}>
          {paymentInfo?.paymentIntent?.client_secret && (
            <Elements
              stripe={promise}
              options={{
                clientSecret: paymentInfo.paymentIntent.client_secret,
              }}
            >
              <CheckoutForm
                paymentInfo={paymentInfo}
                plan={plan}
                agreedToToS={agreedToToS}
              />
            </Elements>
          )}
        </Col>
        <Col lg={1}></Col>
      </Row>
      <Legalese />
    </>
  );
};

export default StripePaymentPage;
