import * as React from 'react';
import LoginSignup from 'app/components/_Shared/LoginSignup';
import constants from '../../config/constants';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Legalese from 'app/components/Legalese';

export function LoginPage(props) {
  return (
    <>
      <Row className="my-5">
        <Col md={2}></Col>
        <Col>
          <LoginSignup
            className="my-lg-5"
            useAuthSliceSlice={useAuthSliceSlice}
            includeFirstName={true}
            includeLastName={true}
            includePhone={true}
            includeOrganization={true}
            includePassword={true}
            includeTitle={true}
            legalese={<Legalese marginTop />}
            loginCTA="Login to Visitor Log"
            loginHeader="Login to Visitor Log"
            loginText="You already know what this is all about. Login with your Visitor Log account now."
            signupHeader="Sign Up"
            signupText={
              <>
                Sign up for Visitor Log to see how deep the the rabbit hole
                goes.
              </>
            }
            signupCTA="Sign Up"
            googleClientId={constants.GOOGLE_CLIENT_ID()}
            googleClientSecret={constants.GOOGLE_CLIENT_SECRET()}
            googleOAuthAccessTokenURL={constants.GOOGLE_OAUTH_ACCESS_TOKEN_URL}
            googleOAuthTokenValidationURL={
              constants.GOOGLE_OAUTH_TOKEN_VALIDATION_URL
            }
            googleRedirectURL={constants.GOOGLE_REDIRECT_URI()}
            googleScope={constants.GOOGLE_SCOPES}
          />
        </Col>
        <Col md={2}></Col>
      </Row>
    </>
  );
}
