import constants from 'app/config/constants';
import axionsInstance from 'utils/api';

export const markVisitorsPurchased = (visitorIds: number[], email: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/subscription/mark_visitors_purchased`;
  return axionsInstance<any>({
    url,
    method: 'POST',
    data: { visitorIds, email },
  }).then(response => response.data);
};

export const markNotEnhancedVisitorsPurchased = body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/subscription/mark_not_enhanced_visitors_purchased`;
  return axionsInstance<any>({
    url,
    method: 'POST',
    data: body,
  }).then(response => response.data);
};

export const updateOrganizationSubscription = (
  email: string,
  plan: number,
  stripeCustomerID: string,
  stripeBasicMeteredSubscriptionItemId: string,
  stripeEnhancedMeteredSubscriptionItemId: string,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/subscription/update_organization_subscription`;
  return axionsInstance<any>({
    url,
    method: 'POST',
    data: {
      email,
      plan,
      stripeCustomerID,
      stripeBasicMeteredSubscriptionItemId,
      stripeEnhancedMeteredSubscriptionItemId,
    },
  }).then(response => response.data);
};
