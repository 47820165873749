import React, { useEffect, useState } from 'react';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import { getPaymentHistory } from 'api/stripe.service';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import constants from '../../config/constants';

const PaymentHistory = () => {
  const userLogged = useSelector(getAuthUser);
  const [paymentHistory, setPaymentHistory] = useState<any>([]);
  const usDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if (userLogged?.email)
      getPaymentHistory(userLogged?.email).then(response => {
        setPaymentHistory(response);
      });
  }, [userLogged]);

  return (
    <Row>
      <Col xs={2} sm={1} />

      <Col>
        <div className="fs-4 mb-3">Billing History</div>
        {paymentHistory.map(payment => (
          <div>
            {`${dayjs(payment.stripeCreatedAtDate).format(
              'MMMM YYYY',
            )}: ${usDollar.format(payment.amount / 100)}`}
          </div>
        ))}
      </Col>

      <Col lg={2}>
        <Button
          variant="secondary"
          className="w-100"
          href={`mailTo:${constants.POINT_OF_CONTACT()}`}
        >
          Questions? Contact Us
        </Button>
      </Col>
    </Row>
  );
};

export default PaymentHistory;
