import Legalese from 'app/components/Legalese';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/esm/ListGroup';

const Contact = () => {
  return (
    <Row className="mt-5">
      <Col md={4}></Col>
      <Col className="bordered p-5">
        <div>
          If you have any questions, please contact QR:
          <ListGroup className="mt-3">
            <ListGroup.Item className="px-0">
              by email at{' '}
              <a href="mailto:support@quantumred.io">support@quantumred.io</a>
            </ListGroup.Item>
            <ListGroup.Item className="px-0">
              by mail at 1900 Campus Commons Drive, Suite 100, Reston VA 20191.
            </ListGroup.Item>
          </ListGroup>
        </div>
        <Legalese marginTop />
      </Col>
      <Col lg={4} />
    </Row>
  );
};

export default Contact;
