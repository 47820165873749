import React from 'react';
import Nav from 'react-bootstrap/Nav';
import constants from '../../config/constants';

const Legalese = ({
  className = '',
  color = 'dark',
  hideDisclaimer = false,
  marginTop = false,
}) => {
  const LegalesePrivacyLink = () => (
    <a
      target="_blank"
      href={`${constants.LANDING_URL()}/privacy`}
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  );

  return (
    <div>
      <Nav
        className={`justify-content-center ${
          !hideDisclaimer ? 'border-bottom' : ''
        } ${marginTop && 'mt-3'} ${className}`}
      >
        <Nav.Item>
          <Nav.Link className={`text-${color}`} target="_blank" href="/faq">
            FAQ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={`text-${color}`}
            target="_blank"
            href={`${constants.LANDING_URL()}/terms`}
          >
            Terms
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={`text-${color}`}
            target="_blank"
            href={`${constants.LANDING_URL()}/privacy`}
          >
            Privacy
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={`text-${color}`} target="_blank" href="/contact">
            Contact
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {!hideDisclaimer && (
        <div className="mt-3">
          We respect your privacy and do not share your personal information
          unless otherwise stated in our <LegalesePrivacyLink />. This website
          uses a pixel tool to identify visitors to the site for internal
          tracking. Your use of this site signifies your consent to this
          technology. You can withdraw your consent at any time by following the
          instructions in our <LegalesePrivacyLink />.
        </div>
      )}
    </div>
  );
};

export default Legalese;
