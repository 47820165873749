import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { toast } from 'react-toastify';
import { useScript } from 'custom-hooks/useScript';

import { registerUser, signInFederatedUser } from 'api/auth.service';
import { useAuthSliceSlice } from 'store/authentication/authentication';

export interface GoogleButtonProps {
  buttonText?: string;
  behaviorType: 'SignIn' | 'SignUp';
  googleClientId: string;
  googleClientSecret: string;
  googleOAuthAccessTokenURL: string;
  googleOAuthTokenValidationURL: string;
  googleRedirectURL: string;
  googleScope: string;
}

export interface GoogleProfile {
  email: string;
  givenName: string;
  familyName: string;
  name: string;
  imageUrl: string;
  idToken: string;
  refreshToken: string;
  provider: string;
}

export interface GoogleProfileWithTokens extends GoogleProfile {
  tokens: { idToken: string; refreshToken: string };
}

export interface UserSession {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  password: string;
  title: string;
  isFederated: boolean;
  passwordExpirationTime: string;
  active: boolean;
  sessionToken: string;
  expiration: number;
  role?: string;
  organizationId: number;
}

const GoogleButton: FC<GoogleButtonProps> = ({
  behaviorType,
  buttonText = 'Sign in with Google',
  googleClientId,
  googleClientSecret,
  googleOAuthAccessTokenURL,
  googleOAuthTokenValidationURL,
  googleRedirectURL,
  googleScope,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    actions: { finishLoginProcess, googleLogin, startLoginProcess },
  } = useAuthSliceSlice();

  const [oAuthClient, setOAuthClient] = useState<any>();

  const { google } = useScript(
    'https://accounts.google.com/gsi/client',
    'google',
  ) as { google: any };

  const makeSignInFederatedUser = async (googleUserProfile: GoogleProfile) => {
    const username = googleUserProfile.email;
    const { idToken, refreshToken } = googleUserProfile;

    return await signInFederatedUser(username, {
      ...googleUserProfile,
      tokens: { idToken, refreshToken },
    });
  };

  const handleGoogleSignIn = data => {
    dispatch(startLoginProcess());
    getTokens(data.code)
      .then(responseToken =>
        getUserProfile(responseToken?.idToken, responseToken?.refreshToken),
      )
      .then(profile => makeSignInFederatedUser(profile as GoogleProfile))
      .then(response => dispatch(googleLogin(response)))
      .catch(error => {
        //dispatch(loginError('Cannot validate user credentials'));
        if (axios.isAxiosError(error)) toast.error(error.response?.data.error);
        throw error;
      })
      .then(() => dispatch(finishLoginProcess()));
  };

  const getTokens = auth_code =>
    axios
      .post(googleOAuthAccessTokenURL, {
        code: auth_code,
        client_id: googleClientId,
        client_secret: googleClientSecret,
        redirect_uri: googleRedirectURL,
        grant_type: 'authorization_code',
      })
      .then(body => {
        const idToken = body.data.id_token;
        const refreshToken = body.data.refresh_token;
        return { idToken, refreshToken };
      })
      .catch(err => {
        console.error(`Error getting refresh token: ${err.message}`);
      });

  const getUserProfile = (idToken, refreshToken) => {
    return axios
      .get(googleOAuthTokenValidationURL, {
        params: {
          id_token: idToken,
        },
      })
      .then(body => {
        const { email, given_name, family_name, name, picture } = body.data;
        return {
          email,
          givenName: given_name,
          familyName: family_name,
          name,
          imageUrl: picture,
          idToken,
          refreshToken,
          provider: 'google',
        };
      })
      .catch(err => {
        return Promise.reject(`Error validating Google token: ${err.message}`);
      });
  };

  const handleGoogleSignUp = data => {
    // dispatch(startLoginProcess());
    getTokens(data.code)
      .then(responseToken =>
        getUserProfile(responseToken?.idToken, responseToken?.refreshToken),
      )
      .then(({ email, familyName, givenName }) => {
        registerUser({
          email,
          firstName: givenName,
          lastName: familyName,
          isFederated: true,
          active: true,
          organizationId: 0,
        }).then(response => {
          if (response) {
            toast.success('User registered successfully');
            navigate('/');
          }
        });
      })
      .catch(error => {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data.error);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (google) {
      const oAuth2Client = google.accounts.oauth2.initCodeClient({
        client_id: googleClientId,
        scope: googleScope,
        access_type: 'offline',
        ux_mode: 'popup',
        callback:
          behaviorType === 'SignIn' ? handleGoogleSignIn : handleGoogleSignUp,
      });
      setOAuthClient(oAuth2Client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  return (
    <div>
      <GoogleLoginButton
        type="light"
        className="social-btn"
        text={buttonText}
        onClick={() => {
          oAuthClient?.requestCode();
        }}
      ></GoogleLoginButton>
    </div>
  );
};

export default GoogleButton;
