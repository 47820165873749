import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TbCircleFilled } from 'react-icons/tb';

const convertFlags = value =>
  value
    .replace(/V_/gi, '')
    .replace(/_/g, ' ')
    .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/(\b[a-z])/g, v => v.toUpperCase());

const enumerate = count => {
  return '0'
    .repeat(count)
    .split('')
    .map((_, index) => index);
};

const getClosestElement = selector => {
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const nearest = Array.from(document.querySelectorAll(selector))
    .map(element => {
      const top = element.getBoundingClientRect().top ?? 0;
      const bottom = element.getBoundingClientRect().bottom ?? 0;
      const distance = Math.min(
        Math.abs(0 - top),
        Math.abs(windowHeight - bottom),
      );

      return {
        distance,
        index: element.getAttribute('data-page-index') ?? 0,
      };
    })
    .sort((a, b) => a.distance - b.distance)?.[0];

  return !!nearest && nearest?.distance <= windowHeight ? nearest : null;
};

const getColorBasedOnPropensity = propensity => {
  if (propensity >= 0.9) return '5';
  else if (propensity >= 0.75) return '4';
  else if (propensity >= 0.5) return '3';
  else if (propensity >= 0.25) return '2';
  else return '1';
};

const getFinalStep = path => {
  const steps = path.split('.');
  return steps.slice(-1)?.[0];
};

const getIdeologyLabel = ideology => {
  if (!ideology) return '';
  else
    return [
      'Conservative',
      'Conservative Leaning',
      'Unaffiliated / Moderate',
      'Liberal Leaning',
      'Liberal',
    ][ideology - 1];
};

const getObjectValue = (object, path) => {
  const subObject = getSubObject(object, path);
  return subObject[getFinalStep(path)];
};

const getObjectDateValue = (object, path) => {
  const value = getObjectValue(object, path);

  if (!!value) return new Date(value).toISOString().split('T')[0];
};

const getPriorityIcon = priority => {
  const meta = {
    C_High: { color: 'danger', tooltip: 'Priority High' },
    B_Medium: { color: 'warning', tooltip: 'Priority Medium' },
    A_Low: { color: 'success', tooltip: 'Priority Low' },
    undefined: { color: 'dark', tooltip: 'Priority Undefined' },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[priority]?.tooltip}</Tooltip>}>
      <span>
        <TbCircleFilled className={`text-${meta?.[priority]?.color}`} />
      </span>
    </OverlayTrigger>
  );
};

const getProcessedTemplate = (templates, use, row) => {
  let content =
    templates
      .map(template =>
        template.use === use && template.isActive ? template.content : null,
      )
      .filter(a => a !== null)?.[0] ?? '';

  Object.keys(row).forEach(key => {
    const regex = new RegExp(`\\[\\[${key}\\]\\]`, 'gi');
    content = content.replace(regex, row[key]);
  });

  return encodeURIComponent(content);
};

const getSMSURL = (phone, message) => `sms:${phone};?&body=${message}`;

const getStatusPill = status => {
  const meta = {
    inProgress: {
      bgColor: 'primary',
      fontColor: 'light',
      label: 'In Progress',
      tooltip: 'In Progress',
    },
    lost: {
      bgColor: 'danger',
      fontColor: 'light',
      label: 'Lost',
      tooltip: 'Lost',
    },
    uncontacted: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Uncontacted',
      tooltip: 'Uncontacted',
    },
    won: {
      bgColor: 'success',
      fontColor: 'light',
      label: 'Won',
      tooltip: 'Won',
    },
    undefined: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Undefined Status',
      tooltip: 'Undefined Status',
    },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[status]?.tooltip}</Tooltip>}>
      <Badge bg={meta?.[status]?.bgColor} text={meta?.[status]?.fontColor}>
        {meta?.[status]?.label}
      </Badge>
    </OverlayTrigger>
  );
};

const getSubObject = (object, path) => {
  const steps = path.split('.');
  let subObject = object;

  while (steps.length > 1) {
    const step = steps.shift();

    if (!(step in subObject)) subObject[step] = {};
    subObject = subObject[step];
  }

  return subObject;
};

const getTallestPageHeight = () => {
  /*Array.from(document.querySelectorAll(`[data-page="true"]`)).forEach(
    page => ((page as HTMLElement).style.height = 'auto'),
  );*/

  return Array.from(document.querySelectorAll(`[data-page="true"]`))
    .map(page => page.offsetHeight)
    .sort()
    .reverse()?.[0];
};

const isGapInRange = (data, idField, min, max) => {
  let priorRowIndex = -1;
  let gapFound = false;

  for (let c = min; c <= max; c++) {
    const rowIndex = Number(
      document
        .getElementById(`plan-view-row-${data?.[c]?.[idField]}`)
        ?.getAttribute('data-index'),
    );

    if (c > min && rowIndex - priorRowIndex > 1) gapFound = true;

    priorRowIndex = rowIndex;
  }

  return gapFound;
};

const isElementRefVisible = ref => {
  const top = ref.current?.getBoundingClientRect().top ?? 0;
  const bottom = ref.current?.getBoundingClientRect().bottom ?? 0;
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const isNowVisible =
    (top > 0 && top < windowHeight) || (bottom > 0 && bottom < windowHeight);

  return isNowVisible;
};

const scrollToPage = (pageIndex, setActivePage = () => {}) => {
  document
    .querySelector(`[data-page-index="${pageIndex}"]`)
    ?.scrollIntoView(true);

  setActivePage(parseInt(pageIndex, 10));
};

const syncPageHeights = pagesLoaded => {
  const tallestHeight = getTallestPageHeight();

  const pages = document.querySelectorAll(`[data-page="true"]`);

  Array.from(pages).forEach((page, index) => {
    if (!pagesLoaded?.[index]) page.style.height = `${tallestHeight}px`;
    else page.style.height = 'auto';
  });
};

export {
  convertFlags,
  enumerate,
  getClosestElement,
  getColorBasedOnPropensity,
  getFinalStep,
  getIdeologyLabel,
  getObjectValue,
  getObjectDateValue,
  getPriorityIcon,
  getProcessedTemplate,
  getSMSURL,
  getStatusPill,
  getSubObject,
  getTallestPageHeight,
  isGapInRange,
  isElementRefVisible,
  scrollToPage,
  syncPageHeights,
};
