import * as React from 'react';
import { MdArrowCircleDown, MdArrowCircleUp } from 'react-icons/md';
import { getObjectValue } from 'app/components/Prospect-Tracker/utility';
import PlanViewHeaderCell from './SpreadsheetHeaderCell';
import FilterDropdown from 'app/components/Prospect-Tracker/components/FilterDropdown';

const SpreadsheetHeader = ({
  activeColumnOptions,
  cellTemplate,
  columnOptions,
  donors,
  onDraggerDrop,
  onDraggerMouseDown,
  onResizerDblClick,
  onResizerMouseDown,
  order,
  query,
  setQuery,
  sort,
  updateSort,
}) => {
  const [data, setData] = React.useState<any[]>([]);
  const [customFilter, setCustomFilter] = React.useState<string>('');

  React.useEffect(() => {
    let values = [
      ...data,
      ...(donors ?? []).map(row => getObjectValue(row, cellTemplate.field)),
    ];
    values = values
      .filter((value, index) => values.indexOf(value) === index)
      .sort();

    if (JSON.stringify(values) !== JSON.stringify(data))
      setData(
        values.filter((value, index) => values.indexOf(value) === index).sort(),
      );
  }, [cellTemplate.field, data, donors]);

  return (
    <PlanViewHeaderCell
      activeColumnOptions={activeColumnOptions}
      cellTemplate={cellTemplate}
      columnOptions={columnOptions}
      onDraggerDrop={onDraggerDrop}
      onDraggerMouseDown={onDraggerMouseDown}
      onResizerDblClick={onResizerDblClick}
      onResizerMouseDown={onResizerMouseDown}
    >
      <div onClick={() => updateSort(cellTemplate.field)}>
        {cellTemplate.header}
        {cellTemplate.field === sort && order === -1 && (
          <>
            <MdArrowCircleDown />
          </>
        )}
        {cellTemplate.field === sort && order === 1 && (
          <>
            <MdArrowCircleUp />
          </>
        )}
      </div>
      <FilterDropdown
        cellTemplate={cellTemplate}
        columnOptions={columnOptions}
        customFilter={customFilter}
        data={data}
        query={query}
        setCustomFilter={setCustomFilter}
        setQuery={setQuery}
      />
    </PlanViewHeaderCell>
  );
};

export default SpreadsheetHeader;
