import React from 'react';
import Button from 'react-bootstrap/Button';
import { MdCheckCircle } from 'react-icons/md';

const TOS = ({ agreedToToS, onAgreement }) => {
  return (
    <div className="text-dark position-relative">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Nulla porttitor
        massa id neque. Aliquam sem fringilla ut morbi. Egestas fringilla
        phasellus faucibus scelerisque eleifend donec pretium vulputate sapien.
        Enim nulla aliquet porttitor lacus luctus. Est velit egestas dui id
        ornare arcu. Eros in cursus turpis massa tincidunt dui ut. Ut sem nulla
        pharetra diam sit. Congue mauris rhoncus aenean vel elit scelerisque
        mauris. Platea dictumst quisque sagittis purus sit. Nec nam aliquam sem
        et tortor consequat id porta. Sed id semper risus in.
      </p>

      <p>
        Eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar.
        Tincidunt praesent semper feugiat nibh sed pulvinar proin. Suspendisse
        faucibus interdum posuere lorem ipsum dolor. Parturient montes nascetur
        ridiculus mus mauris vitae. Risus nec feugiat in fermentum posuere urna
        nec. Ut pharetra sit amet aliquam id diam. Mi tempus imperdiet nulla
        malesuada pellentesque. Nulla facilisi etiam dignissim diam quis. Quam
        viverra orci sagittis eu volutpat odio facilisis mauris sit. Enim tortor
        at auctor urna nunc id. Habitasse platea dictumst quisque sagittis purus
        sit amet volutpat. Sit amet nisl purus in mollis nunc sed id semper.
        Nibh venenatis cras sed felis eget velit aliquet sagittis id. Purus non
        enim praesent elementum facilisis. Scelerisque in dictum non consectetur
        a erat nam at. Vulputate enim nulla aliquet porttitor lacus luctus
        accumsan tortor posuere. Sit amet volutpat consequat mauris. Suspendisse
        sed nisi lacus sed viverra. Risus at ultrices mi tempus imperdiet.
      </p>

      <p>
        Ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget.
        Semper auctor neque vitae tempus quam pellentesque. Nullam eget felis
        eget nunc lobortis mattis aliquam. Magna sit amet purus gravida quis
        blandit turpis cursus in. Amet nisl suscipit adipiscing bibendum est
        ultricies. Orci dapibus ultrices in iaculis nunc sed augue lacus.
        Scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis.
        Eget duis at tellus at urna condimentum mattis pellentesque. Id diam
        maecenas ultricies mi eget. Nullam eget felis eget nunc lobortis mattis
        aliquam. Quis varius quam quisque id diam vel quam elementum. Vitae
        auctor eu augue ut lectus arcu bibendum at. Sit amet luctus venenatis
        lectus magna. Eget egestas purus viverra accumsan in nisl nisi
        scelerisque eu. Non blandit massa enim nec dui nunc mattis enim ut. At
        quis risus sed vulputate odio ut enim blandit. At quis risus sed
        vulputate odio ut. Ut porttitor leo a diam. Pretium viverra suspendisse
        potenti nullam ac tortor vitae purus.
      </p>

      <p>
        A iaculis at erat pellentesque adipiscing commodo elit at imperdiet.
        Euismod elementum nisi quis eleifend quam adipiscing vitae. Tempus quam
        pellentesque nec nam aliquam. Quam quisque id diam vel quam elementum
        pulvinar. Cursus sit amet dictum sit amet justo donec enim diam. Proin
        sed libero enim sed faucibus. Et magnis dis parturient montes nascetur
        ridiculus mus mauris. Malesuada proin libero nunc consequat interdum
        varius sit. Nam aliquam sem et tortor. Iaculis at erat pellentesque
        adipiscing commodo. Diam phasellus vestibulum lorem sed risus ultricies.
        A cras semper auctor neque. Est ante in nibh mauris cursus mattis
        molestie a iaculis. Aliquet eget sit amet tellus cras. Egestas sed
        tempus urna et pharetra pharetra massa massa ultricies. Leo urna
        molestie at elementum eu facilisis. Viverra mauris in aliquam sem
        fringilla ut morbi tincidunt augue. Sit amet dictum sit amet justo donec
        enim. Maecenas accumsan lacus vel facilisis volutpat est velit.
      </p>

      <p>
        Etiam non quam lacus suspendisse faucibus interdum. Tincidunt dui ut
        ornare lectus sit amet. Orci sagittis eu volutpat odio facilisis. Dolor
        morbi non arcu risus quis. Eu consequat ac felis donec et odio
        pellentesque diam volutpat. Diam quis enim lobortis scelerisque. Orci a
        scelerisque purus semper eget duis at. Faucibus in ornare quam viverra
        orci sagittis eu. Pretium fusce id velit ut. Nec feugiat nisl pretium
        fusce id velit. Eget lorem dolor sed viverra ipsum.
      </p>

      <p>
        Aliquet porttitor lacus luctus accumsan tortor posuere ac ut. Purus in
        mollis nunc sed id. Vitae nunc sed velit dignissim sodales. Diam sit
        amet nisl suscipit adipiscing bibendum. Scelerisque varius morbi enim
        nunc faucibus a pellentesque sit amet. Vitae sapien pellentesque
        habitant morbi tristique senectus. Massa tempor nec feugiat nisl pretium
        fusce. Pellentesque massa placerat duis ultricies lacus sed turpis
        tincidunt. Elit ut aliquam purus sit amet luctus venenatis lectus magna.
        Est placerat in egestas erat. Dictum fusce ut placerat orci nulla. Est
        placerat in egestas erat imperdiet sed. Consequat id porta nibh
        venenatis cras. Pellentesque pulvinar pellentesque habitant morbi
        tristique senectus et netus.
      </p>

      <p>
        Magnis dis parturient montes nascetur ridiculus mus mauris. Mattis
        ullamcorper velit sed ullamcorper morbi tincidunt ornare. Ipsum dolor
        sit amet consectetur adipiscing elit duis tristique sollicitudin. Ut
        lectus arcu bibendum at varius vel. Maecenas accumsan lacus vel
        facilisis volutpat est velit egestas. Urna duis convallis convallis
        tellus id interdum. Donec enim diam vulputate ut pharetra. Nullam eget
        felis eget nunc lobortis mattis aliquam. Proin libero nunc consequat
        interdum varius sit amet mattis. Risus quis varius quam quisque. Dui id
        ornare arcu odio ut sem. Nunc lobortis mattis aliquam faucibus purus in
        massa tempor nec. Leo in vitae turpis massa sed elementum tempus egestas
        sed. Tempor orci eu lobortis elementum. Pellentesque habitant morbi
        tristique senectus et netus. Dolor sit amet consectetur adipiscing elit
        pellentesque.
      </p>

      <p>
        Ut morbi tincidunt augue interdum velit euismod in pellentesque massa.
        Quis varius quam quisque id diam vel. Sit amet justo donec enim. In
        aliquam sem fringilla ut morbi tincidunt augue interdum velit. Ac felis
        donec et odio pellentesque. In aliquam sem fringilla ut morbi tincidunt
        augue. Ipsum a arcu cursus vitae congue mauris rhoncus. Molestie ac
        feugiat sed lectus vestibulum mattis ullamcorper. Augue interdum velit
        euismod in pellentesque massa placerat. Purus non enim praesent
        elementum facilisis. Volutpat sed cras ornare arcu dui vivamus arcu
        felis. Etiam sit amet nisl purus in mollis. Vel risus commodo viverra
        maecenas accumsan.
      </p>

      <p>
        Neque gravida in fermentum et sollicitudin ac orci. Morbi tincidunt
        ornare massa eget egestas purus. Sed vulputate odio ut enim blandit.
        Justo donec enim diam vulputate ut pharetra sit amet. Sit amet commodo
        nulla facilisi nullam vehicula ipsum. Molestie nunc non blandit massa
        enim nec. Interdum consectetur libero id faucibus nisl tincidunt. Ornare
        arcu odio ut sem. Vel pharetra vel turpis nunc eget lorem dolor sed. At
        in tellus integer feugiat scelerisque. Tellus id interdum velit laoreet
        id donec ultrices. Purus semper eget duis at. Vel turpis nunc eget lorem
        dolor sed viverra. Magna eget est lorem ipsum. Tincidunt praesent semper
        feugiat nibh sed pulvinar proin gravida. Sapien pellentesque habitant
        morbi tristique senectus et netus.
      </p>

      <p>
        Magna etiam tempor orci eu lobortis elementum nibh tellus. Pellentesque
        adipiscing commodo elit at imperdiet dui accumsan. Nisl nisi scelerisque
        eu ultrices vitae. Faucibus pulvinar elementum integer enim neque
        volutpat ac tincidunt vitae. Risus commodo viverra maecenas accumsan
        lacus. Sed vulputate odio ut enim blandit volutpat maecenas volutpat.
        Felis bibendum ut tristique et egestas quis ipsum suspendisse ultrices.
        Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque.
        Ut ornare lectus sit amet est placerat in. Aliquet sagittis id
        consectetur purus ut faucibus pulvinar elementum integer. Gravida in
        fermentum et sollicitudin ac orci phasellus egestas tellus. In hendrerit
        gravida rutrum quisque non.
      </p>

      <p>
        Nullam non nisi est sit. Diam sit amet nisl suscipit adipiscing
        bibendum. Faucibus in ornare quam viverra. Diam quis enim lobortis
        scelerisque. Volutpat diam ut venenatis tellus. Ut aliquam purus sit
        amet. Faucibus purus in massa tempor nec feugiat. Vestibulum lectus
        mauris ultrices eros. Elementum curabitur vitae nunc sed. Nisl
        condimentum id venenatis a condimentum vitae sapien pellentesque. Aenean
        pharetra magna ac placerat. Penatibus et magnis dis parturient montes
        nascetur ridiculus mus mauris. Ut morbi tincidunt augue interdum velit
        euismod in pellentesque.
      </p>

      <p>
        Nullam non nisi est sit. Ultrices mi tempus imperdiet nulla malesuada
        pellentesque elit eget gravida. Tristique sollicitudin nibh sit amet
        commodo. Scelerisque eleifend donec pretium vulputate. Quis auctor elit
        sed vulputate mi sit. Non pulvinar neque laoreet suspendisse interdum
        consectetur libero id faucibus. Posuere sollicitudin aliquam ultrices
        sagittis orci. Nec feugiat in fermentum posuere urna nec tincidunt
        praesent semper. Diam quis enim lobortis scelerisque. Eget mi proin sed
        libero enim sed. Turpis egestas maecenas pharetra convallis. Eget felis
        eget nunc lobortis mattis aliquam faucibus purus in. Ultricies lacus sed
        turpis tincidunt id aliquet risus feugiat. Dolor sit amet consectetur
        adipiscing. Interdum posuere lorem ipsum dolor sit amet consectetur
        adipiscing. Sed ullamcorper morbi tincidunt ornare massa eget egestas
        purus. Volutpat est velit egestas dui id ornare arcu odio.
      </p>

      <p>
        Praesent semper feugiat nibh sed pulvinar proin gravida hendrerit.
        Sodales neque sodales ut etiam sit. Pellentesque adipiscing commodo elit
        at imperdiet dui accumsan. Non consectetur a erat nam at lectus urna
        duis convallis. Non tellus orci ac auctor augue mauris augue neque.
        Vulputate eu scelerisque felis imperdiet. Id semper risus in hendrerit
        gravida rutrum. Enim sed faucibus turpis in eu. Elementum eu facilisis
        sed odio morbi quis commodo odio. Curabitur vitae nunc sed velit
        dignissim sodales ut eu. Est lorem ipsum dolor sit amet.
      </p>

      <p>
        Sagittis purus sit amet volutpat consequat. Turpis egestas pretium
        aenean pharetra magna. Urna et pharetra pharetra massa massa ultricies
        mi quis hendrerit. Et netus et malesuada fames. Non curabitur gravida
        arcu ac tortor dignissim convallis aenean. Ut sem viverra aliquet eget
        sit amet. Est ante in nibh mauris. At urna condimentum mattis
        pellentesque id nibh tortor id. Ac odio tempor orci dapibus ultrices in
        iaculis nunc. Fusce ut placerat orci nulla pellentesque dignissim enim.
        Arcu cursus vitae congue mauris rhoncus aenean vel. Mi tempus imperdiet
        nulla malesuada pellentesque elit. Est velit egestas dui id ornare arcu
        odio ut. Luctus accumsan tortor posuere ac ut consequat semper viverra.
        Dui sapien eget mi proin sed libero enim. Ullamcorper morbi tincidunt
        ornare massa eget. Mauris pharetra et ultrices neque ornare aenean
        euismod elementum.
      </p>

      <p>
        Nunc vel risus commodo viverra maecenas accumsan. Odio morbi quis
        commodo odio aenean sed adipiscing diam. Mauris rhoncus aenean vel elit
        scelerisque mauris. Interdum velit euismod in pellentesque massa. Magna
        fermentum iaculis eu non diam phasellus. Mi tempus imperdiet nulla
        malesuada pellentesque elit eget gravida cum. Ac orci phasellus egestas
        tellus rutrum tellus pellentesque. Sit amet facilisis magna etiam tempor
        orci eu lobortis elementum. Eu non diam phasellus vestibulum lorem.
        Egestas sed tempus urna et pharetra pharetra massa massa ultricies.
        Elementum facilisis leo vel fringilla est ullamcorper. Ullamcorper
        dignissim cras tincidunt lobortis feugiat vivamus at augue. Pellentesque
        habitant morbi tristique senectus et. Praesent tristique magna sit amet
        purus gravida quis. Vel quam elementum pulvinar etiam. A diam
        sollicitudin tempor id eu nisl nunc mi. Suscipit tellus mauris a diam.
        Massa sed elementum tempus egestas sed sed risus pretium.
      </p>
      <Button className="bg-dark w-100 sticky-bottom" onClick={onAgreement}>
        {agreedToToS && <MdCheckCircle />} I Agree
      </Button>
    </div>
  );
};

export default TOS;
