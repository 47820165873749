import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import constants from '../../config/constants';

const NoDataModal = ({ onHide }) => {
  return (
    <Modal show size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Visitor Log: First Steps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You currently have no visitors.
        <ListGroup className="mt-3">
          <ListGroup.Item>
            If you have just signed-up, we may need to complete some setup.
            Please click{' '}
            <a
              href={`mailTo:${constants.POINT_OF_CONTACT()}?;&subject=${encodeURIComponent(
                'Visitor Log: Installing Pixel',
              )}&body=${encodeURIComponent(
                'I have just signed-up. Is there anything you need to do before I can utilize Visitor Log?',
              )}`}
            >
              here
            </a>{' '}
            to contact us .
          </ListGroup.Item>
          <ListGroup.Item>
            If you have not selected a plan, click <a href="/plan">here</a>.
          </ListGroup.Item>
          <ListGroup.Item>
            If you have not installed the necessary pixel in your site, please
            click{' '}
            <a
              href={`mailTo:${constants.POINT_OF_CONTACT()}?;&subject=${encodeURIComponent(
                'Visitor Log: Installing Pixel',
              )}&body=${encodeURIComponent(
                'Can you provide guidance for installing the Visitor Log pixel on my site?',
              )}`}
            >
              here
            </a>{' '}
            to contact us .
          </ListGroup.Item>
          <ListGroup.Item>
            If you are not sure what steps you should take, please click{' '}
            <a href={`mailTo:${constants.POINT_OF_CONTACT()}`}>here</a> to
            contact us.
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default NoDataModal;
