// App.tsx
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import SpreadsheetHeader from './SpreadsheetHeader';
import { MdOutlineMenu } from 'react-icons/md';
import {
  enumerate,
  getClosestElement,
  isElementRefVisible,
  isGapInRange,
  syncPageHeights,
} from './SpreadsheetUtility';
import constants from 'app/config/constants';
import SpreadsheetPage from './SpreadsheetPage';
import RangeGapModal from './RangeGapModal';
import OptionsModal from './OptionsModal';
import SpreadsheetStatusbar from './SpreadsheetStatusbar';

const Spreadsheet = ({
  activePage,
  cellTemplateList,
  columnOptionList,
  customStatusbarButtons,
  count,
  donors,
  filtersUpdated,
  getPageInsertIndex,
  goToPage,
  idField,
  loading,
  loadRecords,
  onSelectionUpdated,
  order,
  pagesLoaded,
  query,
  saveOptions,
  setActivePage,
  setColumnOptionList,
  setDonors,
  setFiltersUpdated,
  setOrder,
  setQuery,
  setSort,
  sort,
  templates = [],
}) => {
  const [activeColumnOptions, setActiveColumnOptions] = React.useState<any>({});
  const [columnResizerMouseDown, setColumnResizerMouseDown] =
    React.useState(false);
  const interval = React.useRef<any>(null);
  const [mouseDown, setMouseDown] = React.useState(false);
  const [mouseDownRowIndex, setMouseDownRowIndex] = React.useState(0);
  const [range, setRange] = React.useState({ min: -1, max: -1 });
  const rowRef = React.useRef<any>({});
  const rowRefCount = React.useRef<any>(0);
  const [rowVisibility, setRowVisibilty] = React.useState<any>({});
  const updatingRowVisibility = React.useRef(false);
  const scrollTopRef = React.useRef<any>(-1);
  const [shiftDown, setShiftDown] = React.useState(false);
  const [showOptionsModal, setShowOptionsModal] = React.useState(false);
  const [showRangeGapModal, setShowRangeGapModal] = React.useState(false);
  let columnHeaderRed = React.useRef<HTMLElement>();

  const hideRangeGapModal = () => {
    setShowRangeGapModal(false);
    setMouseDown(false);
    setShiftDown(false);
  };

  const onScroll = React.useCallback(() => {
    if (!loading) {
      const nearestPage = getClosestElement(`[data-page="true"]`);
      const nearestUnloadedPage = getClosestElement(
        `[data-page-isloaded="false"]`,
      );

      if (!!nearestPage) setActivePage(parseInt(nearestPage.index, 10));
      if (!!nearestUnloadedPage) loadRecords(nearestUnloadedPage.index);
      else {
        const rowRefKeys = Object.keys(rowRef.current);
        const newScrollTop =
          window.scrollY || document.documentElement.scrollTop;
        const newRowRefCount = rowRefKeys.length;

        if (
          !updatingRowVisibility.current &&
          (newScrollTop !== scrollTopRef.current ||
            newRowRefCount !== rowRefCount.current ||
            filtersUpdated)
        ) {
          updatingRowVisibility.current = true;
          syncPageHeights(pagesLoaded);

          let newRowVisibility = {};
          let changeFound = false;

          rowRefKeys.forEach(key => {
            const visibilityPrior = rowVisibility[key] ?? false;
            const visibilityNow = isElementRefVisible(rowRef.current[key]);

            newRowVisibility[key] = isElementRefVisible(rowRef.current[key]);
            if (visibilityPrior !== visibilityNow) changeFound = true;
          });

          if (changeFound) {
            setRowVisibilty(newRowVisibility);
            if (filtersUpdated) setFiltersUpdated(false);
          }

          updatingRowVisibility.current = false;
          scrollTopRef.current = newScrollTop;
          rowRefCount.current = newRowRefCount;
        }
      }
    }
  }, [
    filtersUpdated,
    loadRecords,
    loading,
    pagesLoaded,
    rowVisibility,
    setActivePage,
    setFiltersUpdated,
  ]);

  const resetRange = index => {
    if (!shiftDown) {
      setMouseDown(true);
      setMouseDownRowIndex(index);
      setRange({ min: index, max: index });
    }
  };

  const updateRange = (index, isMouseDown) => {
    if (mouseDown || shiftDown) {
      const newMin = mouseDownRowIndex <= index ? mouseDownRowIndex : index;
      const newMax = mouseDownRowIndex >= index ? mouseDownRowIndex : index;
      let newRange = { min: newMin, max: newMax };

      if (!isMouseDown) {
        setMouseDown(isMouseDown);

        if (isGapInRange(donors, idField, newMin, newMax)) {
          newRange = { min: newMin, max: newMin };
          setShowRangeGapModal(true);
        } else newRange = { min: newMin, max: newMax };
      } else newRange = { min: newMin, max: newMax };

      setRange(newRange);
      onSelectionUpdated(
        Object.keys('0'.repeat(newRange.max - newRange.min + 1).split('')).map(
          key => newRange.min + parseInt(key, 10),
        ),
      );
    }
  };

  const updateSort = field => {
    if (field === sort) setOrder(order === -1 ? 1 : -1);
    else {
      setSort(field);
      setOrder(-1);
    }
  };

  const onDraggerDrop = (e, columnOptions) => {
    const index = columnOptionList
      .map((a, index) => (a === columnOptions ? index : null))
      .filter(a => a)?.[0];

    const indexDelta = activeColumnOptions.index - index;

    if (indexDelta) {
      columnOptionList.splice(activeColumnOptions.index, 1);

      columnOptionList = [
        ...columnOptionList.slice(0, index),
        activeColumnOptions,
        ...columnOptionList.slice(index),
      ].map((a, index) => ({ ...a, index }));

      setColumnOptionList([...columnOptionList]);

      saveOptions(columnOptionList);
    }
  };

  const onDraggerMouseDown = (e, columnOptions, thRef) => {
    columnHeaderRed.current = thRef.current;
    setActiveColumnOptions(columnOptions);
  };

  const onResizerDblClick = columnOptions => {
    delete columnOptions.width;
    setColumnOptionList([...columnOptionList]);
    setColumnResizerMouseDown(false);
    saveOptions(columnOptionList);
  };

  const onResizerMouseDown = (e, columnOptions, thRef) => {
    columnHeaderRed.current = thRef.current;
    setActiveColumnOptions(columnOptions);
    setColumnResizerMouseDown(true);
  };

  const onMouseMove = e => {
    if (columnResizerMouseDown && columnHeaderRed?.current) {
      const rect = columnHeaderRed.current.getBoundingClientRect();
      const delta = e.clientX - rect.left;

      columnHeaderRed.current.style.minWidth = `${delta}px`;
      columnHeaderRed.current.style.maxWidth = `${delta}px`;

      activeColumnOptions.width = delta;
      setColumnOptionList([...columnOptionList]);
    }
  };

  const onMouseUp = () => {
    if (columnResizerMouseDown) saveOptions(columnOptionList);
    setColumnResizerMouseDown(false);
  };

  React.useEffect(() => {
    if (interval?.current) clearInterval(interval.current);
    interval.current = setInterval(onScroll, 100);

    return () => {
      clearInterval(interval.current);
    };
  }, [onScroll]);

  return (
    <>
      <OptionsModal
        cellTemplateList={cellTemplateList}
        columnOptionList={columnOptionList}
        onHide={() => setShowOptionsModal(false)}
        saveOptions={saveOptions}
        setColumnOptionList={setColumnOptionList}
        show={showOptionsModal}
      />

      <RangeGapModal onHide={hideRangeGapModal} show={showRangeGapModal} />

      <Table
        striped
        bordered
        hover
        size="sm"
        style={{
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
          marginBottom: '55px',
        }}
        tabIndex={0}
        onKeyDown={e => {
          const tagName = (e.target as HTMLElement).tagName.toLowerCase();
          if (['table', 'td', 'tr'].includes(tagName)) setShiftDown(e.shiftKey);
        }}
        onKeyUp={e => setShiftDown(e.shiftKey)}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <thead className="sticky-top top-0">
          <tr>
            <th>
              <Button variant="dark" onClick={() => setShowOptionsModal(true)}>
                <MdOutlineMenu />
              </Button>
            </th>
            {columnOptionList.map(columnOptions => {
              const cellTemplate = cellTemplateList.filter(
                a => a.field === columnOptions.field,
              )?.[0];

              return (
                columnOptions.isVisible &&
                cellTemplate.showInDesktop && (
                  <SpreadsheetHeader
                    activeColumnOptions={activeColumnOptions}
                    key={`${cellTemplate.field}`}
                    cellTemplate={cellTemplate}
                    columnOptions={columnOptions}
                    donors={donors}
                    onDraggerDrop={onDraggerDrop}
                    onDraggerMouseDown={onDraggerMouseDown}
                    onResizerDblClick={onResizerDblClick}
                    onResizerMouseDown={onResizerMouseDown}
                    order={order}
                    query={query}
                    setQuery={setQuery}
                    sort={sort}
                    updateSort={updateSort}
                  />
                )
              );
            })}
          </tr>
        </thead>
        {enumerate(Math.ceil(count / constants.DPT_LOAD_LIMIT())).map(
          pageIndex => (
            <SpreadsheetPage
              cellTemplateList={cellTemplateList}
              columnOptionList={columnOptionList}
              donors={donors}
              getPageInsertIndex={getPageInsertIndex}
              idField={idField}
              pageIndex={pageIndex}
              pagesLoaded={pagesLoaded}
              range={range}
              rowRef={rowRef}
              rowVisibility={rowVisibility}
              resetRange={resetRange}
              setDonors={setDonors}
              templates={templates}
              updateRange={updateRange}
            />
          ),
        )}
      </Table>

      <SpreadsheetStatusbar
        activePage={activePage}
        count={count}
        customStatusbarButtons={customStatusbarButtons}
        goToPage={goToPage}
      />
    </>
  );
};

export default Spreadsheet;
