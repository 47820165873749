import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = ({
  message,
  onCancel,
  onHide,
  onOK,
  show,
  title,
}) => {
  return (
    <Modal onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button className="text-dark" variant="secondary" onClick={onOK}>
            OK
          </Button>
          <Button className="text-dark" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
