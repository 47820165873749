import * as React from 'react';

const SpreadsheetRow = ({
  cellTemplateList,
  columnOptionList,
  donors,
  idField,
  range,
  resetRange,
  row,
  rowIndex,
  rowIndexLabel,
  rowRef,
  rowVisibility,
  setDonors,
  templates,
  updateRange,
}) => {
  const trRef = React.useRef<HTMLTableRowElement>(null);

  React.useEffect(() => {
    if (!!row?.[idField]) rowRef.current[row?.[idField]] = trRef;
  }, [idField, row, rowRef]);

  return (
    <tr
      id={`plan-view-row-${row?.[idField]}}`}
      data-index={rowIndexLabel}
      ref={trRef}
      className={
        rowIndex >= range.min && rowIndex <= range.max ? 'table-primary' : ''
      }
      onMouseDown={e => {
        const tagName = (e.target as HTMLElement).tagName.toLowerCase();
        if (
          ['table', 'td', 'tr'].includes(tagName) ||
          rowIndex < range.min ||
          rowIndex > range.max
        )
          resetRange(rowIndex);
      }}
      onMouseMove={() => updateRange(rowIndex, true)}
      onMouseUp={() => updateRange(rowIndex, false)}
    >
      <td>
        <small className="text-secondary">{rowIndexLabel + 1}</small>
      </td>
      {!!rowVisibility?.[row?.[idField]] &&
        columnOptionList.map(columnOptions => {
          const cellTemplate = cellTemplateList.filter(
            a => a.field === columnOptions.field,
          )?.[0];

          return (
            columnOptions.isVisible &&
            cellTemplate.showInDesktop && (
              <td
                key={`row-${row?.[idField]}-${cellTemplate.field}`}
                style={{
                  maxWidth:
                    columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
                  minWidth:
                    columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
                  width: columnOptions?.width ?? 'fit-content',
                  whiteSpace: !!columnOptions?.width ? undefined : 'nowrap',
                }}
              >
                <small>
                  {cellTemplate.contentHandler(
                    row,
                    templates,
                    cellTemplate,
                    columnOptions,
                    Object.keys(
                      '0'.repeat(range.max - range.min + 1).split(''),
                    ).map(key => range.min + parseInt(key, 10)),
                    donors,
                    setDonors,
                  )}
                </small>
              </td>
            )
          );
        })}
    </tr>
  );
};

export default SpreadsheetRow;
