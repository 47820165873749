import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export interface EnvJSONSliceState {
  DPT_LOAD_LIMIT?: number;
  DPT_SAVE_LIMIT?: number;
  GOOGLE_CLIENT_ID?: string;
  GOOGLE_CLIENT_SECRET?: string;
  GOOGLE_REDIRECT_URI?: string;
  BASE_ENDPOINT?: string;
  STRIPE_PUBLIC_KEY?: string;
  ENABLE_ENHANCED_PLAN?: boolean;
  POINT_OF_CONTACT?: string;
  LANDING_URL?: string;
}

export const initialState: EnvJSONSliceState = {
  DPT_LOAD_LIMIT: 20,
  DPT_SAVE_LIMIT: 20,
  GOOGLE_CLIENT_ID: '',
  GOOGLE_CLIENT_SECRET: '',
  GOOGLE_REDIRECT_URI: '',
  BASE_ENDPOINT: '',
  STRIPE_PUBLIC_KEY: '',
  ENABLE_ENHANCED_PLAN: false,
  POINT_OF_CONTACT: '',
  LANDING_URL: '',
};

const envJSONSlice = createSlice({
  name: 'envJSON',
  initialState,
  reducers: {
    loadEnvJSON: (
      state,
      action: PayloadAction<EnvJSONSliceState, string, never>,
    ) => {
      state.DPT_LOAD_LIMIT = action.payload.DPT_LOAD_LIMIT;
      state.DPT_SAVE_LIMIT = action.payload.DPT_SAVE_LIMIT;
      state.GOOGLE_CLIENT_ID = action.payload.GOOGLE_CLIENT_ID;
      state.GOOGLE_CLIENT_SECRET = action.payload.GOOGLE_CLIENT_SECRET;
      state.GOOGLE_REDIRECT_URI = action.payload.GOOGLE_REDIRECT_URI;
      state.BASE_ENDPOINT = action.payload.BASE_ENDPOINT;
      state.STRIPE_PUBLIC_KEY = action.payload.STRIPE_PUBLIC_KEY;
      state.ENABLE_ENHANCED_PLAN = action.payload.ENABLE_ENHANCED_PLAN;
      state.POINT_OF_CONTACT = action.payload.POINT_OF_CONTACT;
      state.LANDING_URL = action.payload.LANDING_URL;
    },
  },
});

export const useEnvJSONSlice = () => {
  useInjectReducer({
    key: envJSONSlice.name,
    reducer: envJSONSlice.reducer,
  });
  return { actions: envJSONSlice.actions };
};

export default envJSONSlice.reducer;
