import React, { useRef, useState } from 'react';
import './SpreadsheetHeaderCell.style.css';
import { TbGripHorizontal } from 'react-icons/tb';

const SpreadsheetHeaderCell = ({
  activeColumnOptions,
  cellTemplate,
  children,
  columnOptions,
  onDraggerDrop,
  onDraggerMouseDown,
  onResizerDblClick,
  onResizerMouseDown,
}) => {
  const thRef = useRef<any>(null);
  const [className, setClassName] = useState('');

  const onDragOver = e => {
    e.preventDefault();
    const indexDelta = activeColumnOptions.index - columnOptions.index;

    if (indexDelta)
      setClassName(indexDelta > 0 ? 'dragOverRight' : 'dragOverLeft');
  };

  const onDragLeave = () => {
    setClassName('');
  };

  return (
    <th
      ref={thRef}
      className={`planViewHeaderCell text-nowrap ${className}`}
      key={`header-${cellTemplate.header}`}
      onDrop={e => onDraggerDrop(e, columnOptions)}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onMouseLeave={onDragLeave}
      style={{
        cursor: 'pointer',
        maxWidth: columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
        minWidth: columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
        width: columnOptions?.width ?? 'fit-content',
        whiteSpace: 'nowrap',
      }}
    >
      {children}

      <div
        className="dragHandle"
        draggable
        onMouseDown={e => onDraggerMouseDown(e, columnOptions, thRef)}
        onTouchStart={e => onDraggerMouseDown(e, columnOptions, thRef)}
      >
        <TbGripHorizontal />
      </div>

      <div className="dropLeftIndicator" />

      <div className="dropRightIndicator" />

      <div
        className="resizeHandle"
        onDoubleClick={() => onResizerDblClick(columnOptions)}
        onMouseDown={e => onResizerMouseDown(e, columnOptions, thRef)}
        onTouchStart={e => onResizerMouseDown(e, columnOptions, thRef)}
      />
    </th>
  );
};

export default SpreadsheetHeaderCell;
