import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

const HelpModal = ({ onHide, show }) => {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Q/A</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>How do I hide/show a column?</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item>Click the topleft menu button.</ListGroup.Item>
                <ListGroup.Item>
                  Selecte the <i>Columns</i> tab.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click a button to toggle the corresponding column.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How do I reposition a column?</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item>
                  Hover your mouse over the header of the column you'd like to
                  reposition.
                </ListGroup.Item>
                <ListGroup.Item>
                  Ensure your mouse is over the top center of the column header.
                  A handle should appear.
                </ListGroup.Item>
                <ListGroup.Item>
                  While holding down the left mouse button, drag the column to
                  the desired position.
                </ListGroup.Item>
                <ListGroup.Item>
                  Release the left-mouse button to place the column.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>How do I resize a column?</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item>
                  Hover your mouse over the header of the column you'd like to
                  resize.
                </ListGroup.Item>
                <ListGroup.Item>
                  Move your mouse to the right or left edge of said column
                  header.
                </ListGroup.Item>
                <ListGroup.Item>
                  While holding down the left mouse button, drag the edge.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>How do I sort the data?</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item>
                  Hover your mouse over the header of the column you'd like to
                  sort by.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click the name of column you'd like to sort by.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click the column name again to reverse the sort order.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How do I filter the data?</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item>
                  Locate the header of the column you'd like to sort by.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click the dropdown below the column name.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click one or more of the values listed in the dropdown to
                  filter by that column and the selected values.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click one or more of the previously clicked values to deselect
                  the filter(s).
                </ListGroup.Item>
                <ListGroup.Item>
                  Enter a value in the input at the top of the dropdown and
                  press the magifying glass to apply a filter not listed.
                </ListGroup.Item>
                <ListGroup.Item>
                  Click the x to the right of the input to remove the custom
                  filter.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
