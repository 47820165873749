import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { CPaymentIntent } from './interfaces';

type CreateCustomer = {
  email: string;
  name: string;
  phone: string;
};
export const createCustomer = async ({
  email,
  name,
  phone,
}: CreateCustomer) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/customers`;

  return axiosInstance<any>({
    url,
    method: 'POST',
    data: { email, name, phone },
  }).then(response => response.data);
};

type CreatePaymentIntent = {
  customer: string;
  description?: string;
  customerPayload?: unknown;
  receipt_email?: string;
  metadata: { [key: string]: any };
  plan: number;
};

export const createPaymentIntent = ({
  customer,
  description,
  customerPayload,
  metadata,
  organizationId,
  plan,
}: CreatePaymentIntent & { dreIds?: number[]; organizationId?: number }) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/paymentIntents`;
  return axiosInstance<CPaymentIntent>({
    url,
    method: 'POST',
    data: {
      customer,
      description,
      customerPayload,
      metadata,
      organizationId,
      plan,
    },
  }).then(response => response.data);
};

export const getPaymentIntent = (id: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/paymentIntents/${id}`;
  return axiosInstance<PaymentIntent>({
    url,
    method: 'GET',
  }).then(response => response.data);
};

export const listPaymentMethods = (
  customerId: string,
  type: { type: string },
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/customers/listPaymentMethods`;
  return axiosInstance<{
    data: PaymentMethod[];
    has_more: boolean;
    object: string;
    url: string;
  }>({
    url,
    method: 'POST',
    data: { customerId, type },
  }).then(response => response.data);
};

export const confirmPaymentIntent = (
  paymentIntentId: string,
  paymentMethodId: string,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/paymentIntents/confirm`;
  return axiosInstance<PaymentIntent>({
    url,
    method: 'POST',
    data: { paymentIntentId, paymentMethodId },
  }).then(response => response.data);
};

export const getPaymentHistory = (email: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/stripe/paymentHistory`;
  return axiosInstance<any>({
    url,
    method: 'POST',
    data: {
      email,
    },
  }).then(response => response.data);
};
