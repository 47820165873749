/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ResetPasswordPage from 'app/pages/ResetPasswordPage';
import { LoginPage } from 'app/pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthState,
  getAuthUser,
} from 'store/authentication/authentication.selector';
import { restoreSession } from 'api/auth.service';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { useEnvJSONSlice } from 'app/config/envJSONSlice';
import 'react-toastify/dist/ReactToastify.css';
import { SubscriptionPlanPage } from './pages/SubscriptionPlanPage';
import StripePaymentPage from './pages/StripePaymentPage';
import { getOneOrganization } from 'api/organization.service';
import { DataPage } from './pages/DataPage';
import { ToastContainer } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import './style.css';
import logo from './assets/vlog.png';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';

export function App() {
  const authState = useSelector(getAuthState);
  const currentUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const {
    actions: { makeLogout },
  } = useAuthSliceSlice();
  const [organization, setOrganization] = React.useState<any>(null);
  const [organizationMissing, setOrganizationMissing] =
    React.useState<any>(false);

  const [envJSONRetrieved, setEnvJSONRetrieved] = useState(false);
  const {
    actions: { googleLogin },
  } = useAuthSliceSlice();
  const {
    actions: { loadEnvJSON },
  } = useEnvJSONSlice();

  const sessionToken = sessionStorage.getItem('Session-Token');
  const expiration = sessionStorage.getItem('expiration');

  const AppWrapper = ({ children }) => {
    return (
      <div>
        <Navbar expand="lg" bg="light" data-bs-theme="light">
          <Navbar.Brand>
            <Image fluid src={logo} style={{ maxHeight: '24px' }} />
          </Navbar.Brand>
          {!!currentUser?._id && (
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          )}
          <Navbar.Collapse>
            {!!currentUser?._id && (
              <Nav className="me-auto">
                <Nav.Link href="/log">Log</Nav.Link>
                <Nav.Link href="/plan">Plan</Nav.Link>
                <Button variant="link" onClick={() => dispatch(makeLogout())}>
                  Logout
                </Button>
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
        <Container fluid>{children}</Container>
      </div>
    );
  };

  useEffect(() => {
    fetch('/env.json')
      .then(res => res.json())
      .then(data => {
        dispatch(loadEnvJSON(data));
        setEnvJSONRetrieved(true);
      });
  }, [dispatch, loadEnvJSON]);

  useEffect(() => {
    if (!envJSONRetrieved) return;
    // check if a valid session exists & restore it
    const now = Date.now();
    const hasValidExpiration = expiration && Number(expiration) - now > 0;
    if (sessionToken && hasValidExpiration && !authState.isAuthenticated) {
      restoreSession(sessionToken).then(response =>
        dispatch(googleLogin(response)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envJSONRetrieved]);

  useEffect(() => {
    if (!!currentUser && currentUser?.organizationId)
      getOneOrganization({ Organization_ID: currentUser?.organizationId }).then(
        response => setOrganization(response),
      );
    else if (!!currentUser) setOrganizationMissing(true);
  }, [currentUser]);

  if (!envJSONRetrieved || !authState)
    return (
      <AppWrapper>
        <>
          <div className="mt-5 text-center">
            <Spinner variant="dark" />
          </div>
        </>
      </AppWrapper>
    );
  else if (organizationMissing)
    return (
      <AppWrapper>
        <>
          <DataPage />
        </>
      </AppWrapper>
    );
  else if (!authState.isAuthenticated && !sessionToken)
    return (
      <AppWrapper>
        <>
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/reset" element={<ResetPasswordPage />} />
              <Route index element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </>
      </AppWrapper>
    );
  else
    return (
      <AppWrapper>
        <>
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route
                index
                element={
                  !organization ? null : !organization?.plan ? (
                    <SubscriptionPlanPage />
                  ) : (
                    <DataPage />
                  )
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/log" element={<DataPage />} />
              <Route path="/plan" element={<SubscriptionPlanPage />} />
              <Route path="/subscribe" element={<StripePaymentPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </>
      </AppWrapper>
    );
}
