import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const downloadCSV = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/visitors/download`;

  return await axiosInstance.post(url, body);
};

export const getVisitorList = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/visitors/records`;

  return await axiosInstance.post(url, body);
};
