import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const OptionsModal = ({
  cellTemplateList,
  columnOptionList,
  onHide,
  saveOptions,
  setColumnOptionList,
  show,
}) => {
  const toggleColumn = columnOptions => {
    columnOptions.isVisible = !(columnOptions?.isVisible ?? false);
    setColumnOptionList([...columnOptionList]);
    saveOptions(columnOptionList);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="columns">
          <Tab eventKey="columns" title="Columns">
            <Row>
              {columnOptionList.map(columnOptions => {
                const cellTemplate = cellTemplateList.filter(
                  a => a.field === columnOptions.field,
                )?.[0];

                return (
                  cellTemplate.showInDesktop && (
                    <Col className="mt-3" sm={6} md={4}>
                      <Button
                        className="w-100 text-dark"
                        variant={columnOptions?.isVisible ? 'primary' : 'light'}
                        onClick={() => toggleColumn(columnOptions)}
                      >
                        {cellTemplate.header}
                      </Button>
                    </Col>
                  )
                );
              })}
            </Row>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default OptionsModal;
