import React, { useState } from 'react';
import GoogleLoginButton from '../GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import { signInUser } from 'api/auth.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const Login = ({
  googleClientId,
  googleClientSecret,
  googleOAuthAccessTokenURL,
  googleOAuthTokenValidationURL,
  googleRedirectURL,
  googleScope,
  legalese,
  loginHeader,
  loginCTA,
  onToggle,
  useAuthSliceSlice,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const {
    actions: { usernamePasswordLogin },
  } = useAuthSliceSlice();

  const onSubmit = async e => {
    e.preventDefault();

    if (!email) setError('Username is required.');
    else if (!password) setError('Password is required.');
    else
      signInUser(email ?? '', password ?? '')
        .then(response => {
          if (response.passwordExpirationTime) {
            navigate(`/reset?email=${email}`);
          } else {
            dispatch(usernamePasswordLogin(response));
            navigate('/');
          }
        })
        .catch(error => {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.error);
          }
        });
  };

  return (
    <Row>
      <Col md={3}></Col>
      <Col className="bordered p-5">
        <div className="text-center fs-4">{loginHeader}</div>
        <div className="text-center">
          Need a log-in?&nbsp;
          <Button
            className="align-text-top rounded-0 border-0 p-0 m-0 text-decoration-none"
            variant="link"
            onClick={onToggle}
          >
            Sign up
          </Button>
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Control
            className="mt-3"
            type="email"
            value={email}
            placeholder="Username"
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Control
            className="mt-3"
            type="password"
            value={password}
            placeholder="Password"
            onChange={e => setPassword(e.target.value)}
          />
          {!!error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          <Button type="submit" className="w-100 mt-3">
            {loginCTA}
          </Button>
        </Form>
        {
          <GoogleLoginButton
            behaviorType="SignIn"
            googleClientId={googleClientId}
            googleClientSecret={googleClientSecret}
            googleOAuthAccessTokenURL={googleOAuthAccessTokenURL}
            googleOAuthTokenValidationURL={googleOAuthTokenValidationURL}
            googleRedirectURL={googleRedirectURL}
            googleScope={googleScope}
          />
        }
        <div>{legalese}</div>
      </Col>
      <Col md={3}></Col>
    </Row>
  );
};

export default Login;
