import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import Spreadsheet from 'app/components/_Shared/Spreadsheet';
import constants from 'app/config/constants';
import { downloadCSV, getVisitorList } from 'api/visitors.service';
import 'bootstrap/dist/css/bootstrap.min.css';
import { insertProspectTrackerOptionsRecords } from 'api/prospect.tracker.options.service';
import { getProspectTrackerOptions } from 'api/prospect.tracker.options.service';
import { cellTemplateList } from './cellTemplateList';
import { scrollToPage } from 'app/components/_Shared/Spreadsheet/SpreadsheetUtility';
import {
  markVisitorsPurchased,
  markNotEnhancedVisitorsPurchased,
} from 'api/subscription.service';
import { toast } from 'react-toastify';
import { MdDownload } from 'react-icons/md';
import dayjs from 'dayjs';
import ConfirmationModal from 'app/components/_Shared/ConfirmationModal';
import NoDataModal from './NoDataModal';
import Legalese from 'app/components/Legalese';

export function DataPage() {
  const userLogged = useSelector(getAuthUser);

  const [activePage, setActivePage] = React.useState(0);
  const [columnOptionList, setColumnOptionList] = React.useState<any[]>([]);
  const [count, setCount] = React.useState(-1);
  const [dataError, setDataError] = React.useState<any>(false);
  const [donors, setDonors] = React.useState<any>(null);
  const [enhancable, setEnhancable] = React.useState<any>([]);
  const [filtersUpdated, setFiltersUpdated] = React.useState<boolean>(false);
  const [initialDonorCount, setInitialDonorCount] = React.useState<any>(null);
  const [hideNoDataModal, setHideNoDataModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notEnhanced, setNotEnhanced] = React.useState(0);
  const [order, setOrder] = React.useState(-1);
  const [pagesLoaded, setPagesLoaded] = React.useState({});
  const [query, setQuery] = React.useState({});
  const [saveQueue, setSaveQueue] = React.useState<any[]>([]);
  const [showEnhanceAllModal, setShowEnhanceAllModal] = React.useState(false);
  const [showEnhanceSelectedModal, setShowEnhanceSelectedModal] =
    React.useState(false);
  const [sort, setSort] = React.useState('Email');

  const getPageInsertIndex = React.useCallback(
    page =>
      Object.keys(pagesLoaded).filter(key => parseInt(key, 10) < page).length *
      constants.DPT_LOAD_LIMIT(),
    [pagesLoaded],
  );

  const getQueryAndSortBasedOnViewMode = React.useCallback(() => {
    return {
      query: query,
      sort: [[sort, order]],
    };
  }, [order, query, sort]);

  const goToPage = pageIndex => {
    loadRecords(pageIndex);
    scrollToPage(pageIndex);
  };

  const loadRecords = React.useCallback(
    pageIndex => {
      if (!pagesLoaded[pageIndex] && !loading) {
        setLoading(true);

        getVisitorList({
          email: userLogged!.email,
          limit: constants.DPT_LOAD_LIMIT(),
          page: parseInt(pageIndex, 10),
          ...getQueryAndSortBasedOnViewMode(),
        }).then(response => {
          if ((response as any)?.status === 500) {
            setDataError(true);
          } else {
            const insertIndex = getPageInsertIndex(pageIndex);

            if (initialDonorCount === null)
              setInitialDonorCount(parseInt(response?.data?.count ?? 0, 10));
            setCount(parseInt(response?.data?.count, 10));
            setNotEnhanced(parseInt(response?.data?.notEnhanced, 10));

            setDonors([
              ...(donors ?? []).slice(0, insertIndex),
              ...response.data.donors,
              ...(donors ?? []).slice(insertIndex),
            ]);
            setPagesLoaded({ ...pagesLoaded, [pageIndex]: true });
            setLoading(false);
          }
        });
      }
    },
    [
      donors,
      getPageInsertIndex,
      getQueryAndSortBasedOnViewMode,
      initialDonorCount,
      loading,
      pagesLoaded,
      userLogged,
    ],
  );

  const onDownloadClick = async () => {
    try {
      const test = await downloadCSV({
        email: userLogged!.email,
        limit: null,
        ...getQueryAndSortBasedOnViewMode(),
      });

      const blob = new Blob([test.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `data${dayjs().format(' YYYY-MM-DD HH.mm.ss')}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const upgradeAll = async () => {
    try {
      setEnhancable([]);
      setShowEnhanceAllModal(false);

      markNotEnhancedVisitorsPurchased({
        email: userLogged!.email,
        limit: null,
        ...getQueryAndSortBasedOnViewMode(),
      }).then(response => {
        reset();
        const tally = response.newVisitorIds.length;
        toast.success(
          `Upgraded ${tally} record(s) successfully! Data has been reloaded`,
        );
      });
    } catch (error) {
      console.error('Error updating visitors:', error);
    }
  };

  const upgradeSelected = () => {
    const buffer = enhancable;
    setEnhancable([]);
    setShowEnhanceSelectedModal(false);

    markVisitorsPurchased(
      buffer.map(item => item._id),
      userLogged!.email,
    ).then(response => {
      reset();
      const tally = response.newVisitorIds.length;
      toast.success(
        `Upgraded ${tally} record(s) successfully! Data has been reloaded`,
      );
    });
  };

  const onSelectionUpdated = indexList => {
    setEnhancable(
      indexList.map(index => donors[index]).filter(item => !item.enhanced),
    );
  };

  const reset = () => {
    setDonors(null);
    setPagesLoaded({});
    loadRecords(0);
    window.scrollTo(0, 0);
  };

  const saveOptions = updatedColumnsOptionList => {
    insertProspectTrackerOptionsRecords({
      email: userLogged!.email,
      options: updatedColumnsOptionList,
    });
  };

  const updateDonors = delta => {
    delta.forEach(item => (donors[item.index] = item.data));
    setSaveQueue([
      ...saveQueue,
      ...delta.map(item => ({
        ...item.data.prospectTracker,
        prospectId: item.data._id,
        orgId: userLogged?.organizationId,
      })),
    ]);
    setDonors([...donors]);
  };

  React.useEffect(() => {
    if (userLogged?.email && !loading) loadRecords(0);
  }, [loadRecords, loading, userLogged?.email]);

  React.useEffect(() => {
    setDonors(null);
    setPagesLoaded({});
    setFiltersUpdated(true);
  }, [order, query, sort]);

  React.useEffect(() => {
    if (!columnOptionList?.length) {
      setColumnOptionList(
        cellTemplateList.map((cellTemplate, index) => ({
          field: cellTemplate.field,
          index,
          isVisible: true,
        })),
      );

      if (userLogged?.email)
        getProspectTrackerOptions({
          email: userLogged?.email,
        }).then(response => {
          if (!!response.data?.options?.length)
            setColumnOptionList(response.data?.options);
        });
    }
  }, [columnOptionList?.length, userLogged]);

  return (
    <>
      {(initialDonorCount === 0 || !!dataError) && !hideNoDataModal && (
        <NoDataModal onHide={() => setHideNoDataModal(true)} />
      )}
      <ConfirmationModal
        show={showEnhanceAllModal}
        onCancel={() => setShowEnhanceAllModal(false)}
        onHide={() => setShowEnhanceAllModal(false)}
        onOK={() => upgradeAll()}
        message={`You are about to purchase ${notEnhanced} Record${
          notEnhanced > 1 ? 's' : ''
        }. Are you certain you want to continue? This action cannot be undone.`}
        title={`Upgrade ${notEnhanced} Record${notEnhanced > 1 ? 's' : ''}`}
      />

      <ConfirmationModal
        show={showEnhanceSelectedModal}
        onCancel={() => setShowEnhanceSelectedModal(false)}
        onHide={() => setShowEnhanceSelectedModal(false)}
        onOK={() => upgradeSelected()}
        message={`You are about to purchase ${enhancable.length} Record${
          enhancable.length > 1 ? 's' : ''
        }. Are you certain you want to continue? This action cannot be undone.`}
        title={`Upgrade ${enhancable.length} Record${
          enhancable.length > 1 ? 's' : ''
        }`}
      />

      <Row>
        <Col>
          <Spreadsheet
            activePage={activePage}
            cellTemplateList={cellTemplateList}
            columnOptionList={columnOptionList}
            count={count}
            donors={donors}
            filtersUpdated={filtersUpdated}
            getPageInsertIndex={getPageInsertIndex}
            goToPage={goToPage}
            idField={'_id'}
            loading={loading}
            loadRecords={loadRecords}
            pagesLoaded={pagesLoaded}
            onSelectionUpdated={onSelectionUpdated}
            order={order}
            query={query}
            saveOptions={saveOptions}
            setActivePage={setActivePage}
            setColumnOptionList={setColumnOptionList}
            setDonors={updateDonors}
            setFiltersUpdated={setFiltersUpdated}
            setOrder={setOrder}
            setQuery={setQuery}
            setSort={setSort}
            sort={sort}
            customStatusbarButtons={[
              <Button
                className="me-lg-1 mb-1 mb-lg-0"
                onClick={onDownloadClick}
              >
                <MdDownload />
                <span className="d-inline-block d-lg-none">&nbsp;Download</span>
              </Button>,
              !!notEnhanced && false && (
                <Button
                  className="me-lg-1 mb-1 mb-lg-0"
                  onClick={() => setShowEnhanceAllModal(true)}
                >
                  Upgrade {notEnhanced} Record{notEnhanced > 1 ? 's' : ''}
                </Button>
              ),
              !!enhancable.length && false && (
                <Button
                  className="me-lg-1 mb-1 mb-lg-0"
                  onClick={() => setShowEnhanceSelectedModal(true)}
                >
                  Upgrade {enhancable.length} Record
                  {enhancable.length > 1 ? 's' : ''}
                </Button>
              ),
              <Legalese color="dark" hideDisclaimer />,
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
