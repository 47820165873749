// App.tsx
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlanViewRow from './SpreadsheetRow';
import { enumerate } from 'app/components/Prospect-Tracker/utility';
import constants from 'app/config/constants';

const SpreadsheetPage = ({
  cellTemplateList,
  columnOptionList,
  donors,
  getPageInsertIndex,
  idField,
  pageIndex,
  pagesLoaded,
  range,
  resetRange,
  rowRef,
  rowVisibility,
  setDonors,
  templates,
  updateRange,
}) => {
  return (
    <tbody
      id={`plan-view-page-${pageIndex}-${!!pagesLoaded[pageIndex]}`}
      data-page={true}
      data-page-index={pageIndex}
      data-page-isloaded={!!pagesLoaded[pageIndex]}
    >
      {pagesLoaded[pageIndex] &&
        enumerate(constants.DPT_LOAD_LIMIT()).map(pageRowIndex => {
          const rowIndex = getPageInsertIndex(pageIndex) + pageRowIndex;
          const row = donors?.[rowIndex];

          return !!row?.[idField] ? (
            <PlanViewRow
              key={`plan-view-row-${row?.[idField]}`}
              cellTemplateList={cellTemplateList}
              columnOptionList={columnOptionList}
              donors={donors}
              idField={idField}
              range={range}
              resetRange={resetRange}
              row={row}
              rowIndex={rowIndex}
              rowIndexLabel={
                pageIndex * constants.DPT_LOAD_LIMIT() + pageRowIndex
              }
              rowRef={rowRef}
              rowVisibility={rowVisibility}
              setDonors={setDonors}
              templates={templates}
              updateRange={updateRange}
            />
          ) : null;
        })}
    </tbody>
  );
};

export default SpreadsheetPage;
